import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Calendar, DollarSign, X, Gift } from 'lucide-react';
import { formatCurrency, formatEuro } from '../../../utils/format';
import { formatDateDisplay } from '../../../utils/date';
import BlurredNumber from '../../../components/ui/BlurredNumber';
import { calculateCommission } from '../../../utils/commission';
import type { Sale } from '../../../types';

interface MonthlyCommissionHistoryProps {
  sales: Sale[];
  agentId: string;
}

interface MonthlyData {
  month: string;
  commission: number;
  sales: Sale[];
}

export default function MonthlyCommissionHistory({ sales, agentId }: MonthlyCommissionHistoryProps) {
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);

  // Get first sale date
  const firstSale = [...sales].sort((a, b) => 
    new Date(a.saleDate).getTime() - new Date(b.saleDate).getTime()
  )[0];

  // Create array of all months since first sale
  const monthlyData: Record<string, MonthlyData> = {};
  if (firstSale) {
    const startDate = new Date(firstSale.saleDate);
    const currentDate = new Date();
    let currentMonth = new Date(startDate.getFullYear(), startDate.getMonth(), 1);

    while (currentMonth <= currentDate) {
      const monthKey = currentMonth.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
      monthlyData[monthKey] = {
        month: monthKey,
        commission: 0,
        sales: []
      };
      currentMonth.setMonth(currentMonth.getMonth() + 1);
    }
  }

  // Fill in actual sales data
  sales.forEach(sale => {
    const date = new Date(sale.saleDate);
    const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
    const commission = calculateCommission(sale);
    
    let saleCommission = 0;
    let role = '';
    let isPaid = false;

    if (sale.agentId === agentId) {
      saleCommission = commission.agent;
      role = 'Agent principal';
      isPaid = sale.paymentStatus.agent;
      if (sale.bonus) {
        saleCommission += commission.bonus.agent;
      }
    } else if (sale.referrerId === agentId) {
      saleCommission = commission.referrer || 0;
      role = 'Parrain';
      isPaid = sale.paymentStatus.referrer;
      if (sale.bonus?.secondAgentId === agentId) {
        saleCommission += commission.bonus.referrer;
      }
    }
    
    // Handle manual commissions
    if (sale.manualCommissions?.additionalAgents) {
      const additionalAgent = sale.manualCommissions.additionalAgents.find(a => a.agentId === agentId);
      if (additionalAgent) {
        saleCommission = additionalAgent.amount;
        role = 'Agent supplémentaire';
        const index = sale.manualCommissions.additionalAgents.findIndex(a => a.agentId === agentId);
        isPaid = sale.paymentStatus.additionalAgents?.[index] || false;
        // Add bonus if exists
        if (sale.bonus?.agents) {
          const bonusAmount = sale.bonus.agents.find(a => a.agentId === agentId)?.amount || 0;
          saleCommission += bonusAmount;
        }
      }
    }

    if (saleCommission > 0) {
      monthlyData[monthKey].commission += saleCommission;
      monthlyData[monthKey].sales.push(sale);
    }
  });

  // Convert to array and sort by date
  const sortedMonths = Object.values(monthlyData).sort((a, b) => {
    const [monthA, yearA] = a.month.split(' ');
    const [monthB, yearB] = b.month.split(' ');
    
    if (yearA !== yearB) {
      return parseInt(yearB) - parseInt(yearA);
    }
    
    const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 
                   'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
    const monthIndexA = months.indexOf(monthA.toLowerCase());
    const monthIndexB = months.indexOf(monthB.toLowerCase());
    
    return monthIndexB - monthIndexA;
  });
  if (sortedMonths.length === 0) {
    return null;
  }

  return (
    <div className="bg-[#1c1c1e] rounded-2xl overflow-hidden">
      <div className="p-4 border-b border-[#2c2c2e]">
        <div className="flex items-center gap-3">
          <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
            <Calendar className="w-6 h-6 text-[#0a84ff]" />
          </div>
          <div>
            <h3 className="text-[17px] font-medium text-white">Historique des commissions</h3>
            <p className="text-[13px] text-[#98989f]">{sales.length} ventes</p>
          </div>
        </div>
      </div>

      <div className="divide-y divide-[#2c2c2e]">
        {sortedMonths.map((monthData, index) => (
          <div
            key={monthData.month}
            onClick={() => setSelectedMonth(monthData.month)}
            className="p-4 hover:bg-[#2c2c2e] cursor-pointer transition-colors"
          >
            <div className="flex items-center justify-between">
              <div>
                <h4 className="text-[15px] font-medium text-white">{monthData.month}</h4>
                <p className="text-[13px] text-[#98989f]">{monthData.sales.length} ventes</p>
              </div>
              <div className="text-right">
                <p className="text-[17px] font-bold text-white">
                  <BlurredNumber value={formatCurrency(monthData.commission)} />
                </p>
                <p className="text-[13px] text-[#98989f]">
                  <BlurredNumber value={formatEuro(monthData.commission)} />
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Monthly Detail Modal */}
      {selectedMonth && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-xl flex items-center justify-center z-50">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            className="w-full max-w-lg bg-[#1c1c1e] rounded-2xl overflow-hidden m-4"
          >
            {/* Modal Header */}
            <div className="p-4 border-b border-[#2c2c2e]">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                    <Calendar className="w-6 h-6 text-[#0a84ff]" />
                  </div>
                  <div>
                    <h2 className="text-[22px] font-semibold text-white">{selectedMonth}</h2>
                    <p className="text-[13px] text-[#98989f]">
                      {monthlyData[selectedMonth].sales.length} ventes
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => setSelectedMonth(null)}
                  className="p-2 hover:bg-[#2c2c2e] rounded-xl transition-colors"
                >
                  <X className="w-5 h-5 text-[#98989f]" />
                </button>
              </div>
            </div>

            {/* Total Commission */}
            <div className="p-4 border-b border-[#2c2c2e]">
              <div className="flex items-center gap-3 mb-2">
                <DollarSign className="w-5 h-5 text-[#30d158]" />
                <p className="text-[15px] text-[#98989f]">Commission totale</p>
              </div>
              <p className="text-[28px] font-bold text-[#30d158] mb-1">
                <BlurredNumber value={formatCurrency(monthlyData[selectedMonth].commission)} />
              </p>
              <p className="text-[13px] text-[#98989f]">
                <BlurredNumber value={formatEuro(monthlyData[selectedMonth].commission)} />
              </p>
            </div>

            {/* Sales List */}
            <div className="max-h-[60vh] overflow-y-auto">
              {monthlyData[selectedMonth].sales.map((sale) => {
                const commission = calculateCommission(sale);
                let myCommission = 0;
                let role = '';
                let isPaid = false;

                if (sale.agentId === agentId) {
                  myCommission = commission.agent;
                  role = 'Agent principal';
                  isPaid = sale.paymentStatus.agent;
                  if (sale.bonus) {
                    myCommission += commission.bonus.agent;
                  }
                } else if (sale.referrerId === agentId) {
                  myCommission = commission.referrer || 0;
                  role = 'Parrain';
                  isPaid = sale.paymentStatus.referrer;
                  if (sale.bonus?.secondAgentId === agentId) {
                    myCommission += commission.bonus.referrer;
                  }
                } else if (sale.manualCommissions?.additionalAgents) {
                  const additionalAgent = sale.manualCommissions.additionalAgents.find(a => a.agentId === agentId);
                  if (additionalAgent) {
                    myCommission = additionalAgent.amount;
                    role = 'Agent supplémentaire';
                    const index = sale.manualCommissions.additionalAgents.findIndex(a => a.agentId === agentId);
                    isPaid = sale.paymentStatus.additionalAgents?.[index] || false;
                    // Add bonus if exists
                    if (sale.bonus?.agents) {
                      const bonusAmount = sale.bonus.agents.find(a => a.agentId === agentId)?.amount || 0;
                      myCommission += bonusAmount;
                    }
                  }
                }

                return (
                  <div key={sale.id} className="px-4 py-3 border-b border-[#2c2c2e] hover:bg-[#2c2c2e] transition-colors">
                    <div className="flex items-start justify-between">
                      <div>
                        <h4 className="text-[15px] font-medium text-white">{sale.project}</h4>
                        <p className="text-[13px] text-[#98989f]">Unité {sale.unitNumber}</p>
                        <p className="text-[13px] text-[#98989f] mt-1">{role}</p>
                      </div>
                      <div className="text-right">
                        <p className="text-[17px] font-bold text-white">
                          <BlurredNumber value={formatCurrency(myCommission)} />
                        </p>
                        <div className={`mt-2 px-3 py-1 rounded-xl text-[13px] font-medium inline-flex items-center gap-1 ${
                          isPaid
                            ? 'bg-[#26382f] text-[#30d158]'
                            : 'bg-[#3a2c29] text-[#ff453a]'
                        }`}>
                          {isPaid ? (
                            <>
                              <span className="w-2 h-2 rounded-full bg-[#30d158]" />
                              Payée
                            </>
                          ) : (
                            <>
                              <span className="w-2 h-2 rounded-full bg-[#ff453a]" />
                              En attente
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
}