import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useAgents } from '../hooks/useAgents';
import { useAgentSlug } from '../hooks/useAgentSlug'; 
import { LayoutDashboard, Users, DollarSign, FileText, Building, GraduationCap, Info, Baseline as PipelineFunnel, Settings, Home, PalmtreeIcon, Clock, Image, Calculator, ShoppingBag, ChevronDown, Star } from 'lucide-react';
import { motion } from 'framer-motion';
import UserMenu from './ui/UserMenu'; 
import AgentSelector from './AgentSelector';
import { useAgentViewStore } from './AgentSelector';
import LoadingScreen from './LoadingScreen';

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { user } = useAuth();
  const { agent, slug } = useAgentSlug();
  const { agents } = useAgents(); 

  useEffect(() => {
    // Simulate loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  const getNavigation = () => {
    if (agent) {
      return [
        { name: 'Dashboard', href: `/agentcrm/${slug}`, icon: LayoutDashboard, shortName: 'Dash' },
        { name: 'Mes ventes', href: `/agentcrm/${slug}/sales`, icon: DollarSign, shortName: 'Ventes' },
        { name: 'Mes paiements', href: `/agentcrm/${slug}/payments`, icon: Clock, shortName: 'Paie.' },
        { name: 'Office Space', href: `/agentcrm/${slug}/office`, icon: Building, shortName: 'Office', badge: { text: 'NEW', color: 'bg-gradient-to-r from-blue-500 to-indigo-500' } },
        { name: 'Merchandising', href: `/agentcrm/${slug}/merchandising`, icon: ShoppingBag, shortName: 'Shop', badge: { text: 'NEW', color: 'bg-gradient-to-r from-green-500 to-teal-500' } },
        { 
          name: 'Estimation IA', 
          href: `/agentcrm/${slug}/estimation`, 
          icon: Calculator, 
          shortName: 'Est.',
          badge: {
            text: 'AI',
            color: 'bg-gradient-to-r from-indigo-500 to-purple-500'
          }
        },
        { name: 'Information', href: `/agentcrm/${slug}/information`, icon: Info, shortName: 'Info' }
      ];
    }

    // Navigation admin par défaut
    const adminNav = [
      { name: 'Dashboard', href: '/', icon: LayoutDashboard },
      { name: 'Agents', href: '/agents', icon: Users },
      { name: 'Clients', href: '/clients', icon: Users },
      { name: 'Ventes', href: '/sales', icon: DollarSign },
      { name: 'Estimation IA', href: '/ai-estimation', icon: Calculator },
      { name: 'Palm+ Admin', href: '/palmplus/services', icon: Star, badge: { text: 'ADMIN', color: 'bg-gradient-to-r from-blue-500 to-indigo-500' } },
      ...(user?.role === 'admin' ? [
        { name: 'Paramètres', href: '/settings', icon: Settings }
      ] : [])
    ];

    // Navigation client
    const clientNav = [
      { name: 'Dashboard', href: '/palmplus', icon: LayoutDashboard },
      { 
        name: 'Palm+', 
        href: `/palmplus`, 
        icon: Star,
        badge: { 
          text: 'PLUS', 
          color: 'bg-gradient-to-r from-blue-500 to-indigo-500' 
        }
      }
    ];

    // Return appropriate navigation based on user role
    if (user?.role === 'admin') {
      return adminNav;
    } else if (user?.role === 'client') {
      return clientNav;
    }

    return adminNav;
  };

  const navigation = getNavigation();

  const isCurrentRoute = (href: string) => {
    if (agent) {
      // Pour la vue agent, comparer sans l'ID de l'agent
      const currentPath = location.pathname.replace(agent.id, ':agentId');
      const comparePath = href.replace(agent.id, ':agentId');
      return currentPath === comparePath;
    }
    return location.pathname === href;
  };

  return (
    <div className="min-h-screen bg-black fixed inset-0 overflow-y-auto">
      {/* Mobile Header */}
      <header className="fixed top-0 left-0 right-0 h-16 bg-[#1c1c1e]/80 backdrop-blur-xl border-b border-[#2c2c2e] lg:hidden z-40">
        <div className="flex items-center justify-between h-full px-4 max-w-lg mx-auto">
          <div className="flex items-center gap-3">
            <motion.div 
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 260, damping: 20 }}
              className="p-2 bg-[#2c2c2e]/80 backdrop-blur-xl rounded-xl flex items-center justify-center"
            >
              <img
                src="https://palmdubai.fr/templates/Default/img/logo_white.svg"
                alt="Palm Dubai Logo"
                className="h-6 w-auto"
              />
            </motion.div>
            <motion.h1 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="text-[17px] font-semibold text-white"
            >
              {user?.role === 'client' ? 'Palm+' : 'PalmCRM'}
            </motion.h1>
          </div>
          
          <UserMenu />
        </div>
      </header>

      {/* Mobile Bottom Navigation */}
      <nav className="fixed bottom-0 left-0 right-0 bg-[#1c1c1e]/80 backdrop-blur-xl border-t border-[#2c2c2e] lg:hidden z-40">
        <div className="flex justify-around">
          {navigation.map((item) => {
            const isActive = isCurrentRoute(item.href);
            return (
              <Link
                key={item.name}
                to={item.href}
                className={`flex flex-col items-center gap-1 px-2 py-2 text-[10px] font-medium transition-all duration-200 relative ${
                  isActive ? 'text-[#0a84ff]' : 'text-[#98989f]'
                }`}
              >
                <div className="relative">
                  <div className={`
                    p-2 rounded-lg transition-colors duration-200
                    ${isActive ? 'bg-[#0a84ff]/10' : 'bg-transparent'}
                  `}>
                    <item.icon className="w-5 h-5" />
                  </div>
                  {item.badge && (
                    <span className={`absolute -top-1 -right-1 px-1.5 py-0.5 text-[8px] font-bold text-white rounded-full ${item.badge.color}`}>
                      {item.badge.text}
                    </span>
                  )}
                </div>
                <span>
                  {item.shortName || item.name}
                </span>
                {isActive && (
                  <motion.div
                    layoutId="activeMobileTab"
                    className="absolute -bottom-3 left-1/2 -translate-x-1/2 w-12 h-1 bg-[#0a84ff] rounded-full"
                    transition={{ duration: 0.3 }}
                  />
                )}
              </Link>
            );
          })}
        </div>
      </nav>

      {/* Desktop Sidebar */}
      <aside className="hidden lg:flex fixed inset-y-0 left-0 z-30 w-72 bg-[#1c1c1e]/80 backdrop-blur-xl border-r border-[#2c2c2e]">
        <div className="flex flex-col h-full w-full">
          {/* Logo */}
          <div className="h-16 flex items-center gap-3 px-6 border-b border-[#2c2c2e] bg-[#1c1c1e]/80">
            <div className="p-2 bg-[#2c2c2e]/80 backdrop-blur-xl rounded-xl flex items-center justify-center">
              <img
                src="https://palmdubai.fr/templates/Default/img/logo_white.svg"
                alt="Palm Dubai Logo"
                className="h-6 w-auto"
              />
            </div>
            <h1 className="text-[17px] font-semibold text-white">
              {user?.role === 'client' ? 'Palm+' : 'PalmCRM'}
            </h1>
          </div>

          {/* Agent Selector */}
          {user?.role === 'admin' && (
            <div className="px-4 py-4">
              <AgentSelector />
            </div>
          )}
          {!user && agent && (
            <div className="px-4 py-4">
              <div className="p-4 bg-[#2c2c2e] rounded-xl">
                <p className="font-medium text-white">{agent.name}</p>
                <p className="text-[13px] text-[#98989f]">{agent.email}</p>
              </div>
            </div>
          )}

          {/* Navigation */}
          <nav className="flex-1 px-3 py-6 space-y-1 overflow-y-auto bg-[#1c1c1e]/80">
            {navigation.map((item) => {
              const isActive = isCurrentRoute(item.href);
              return (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`
                    flex items-center gap-3 px-4 py-3.5 rounded-xl text-[15px] font-medium
                    transition-all duration-200 group relative w-full
                    ${isActive 
                      ? 'bg-[#2c2c2e] text-[#0a84ff]' 
                      : 'text-[#98989f] hover:bg-[#2c2c2e]'
                    }
                  `}
                >
                  <div className={`
                    w-8 h-8 flex items-center justify-center rounded-xl transition-colors duration-200
                    ${isActive 
                      ? 'bg-[#0a84ff]/10' 
                      : 'bg-[#2c2c2e]'}
                  `}>
                    <item.icon className="w-[18px] h-[18px]" />
                  </div>
                  <span>{item.name}</span>
                  {item.badge && (
                    <span className={`
                      ml-auto px-2 py-1 text-[11px] font-bold text-white rounded-full ${item.badge.color}
                    `}>
                      {item.badge.text}
                    </span>
                  )}
                  {isActive && (
                    <motion.div
                      layoutId="activeTab"
                      className="absolute inset-0 rounded-xl bg-[#0a84ff]/5"
                      transition={{ duration: 0.3 }}
                    />
                  )}
                </Link>
              );
            })}
          </nav>

          {/* User Menu */}
          {user ? (
            <div className="p-4 border-t border-[#2c2c2e] bg-[#1c1c1e]/80">
              <UserMenu />
            </div>
          ) : agent && (
            <div className="p-4 border-t border-[#2c2c2e] bg-[#1c1c1e]/80">
              <Link
                to="/login"
                className="flex items-center justify-center gap-2 px-4 py-3 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 text-[15px] font-medium"
              >
                Se connecter
              </Link>
            </div>
          )}
        </div>
      </aside>

      {/* Main Content */}
      <main className="lg:ml-72 min-h-screen pb-24 lg:pb-8 mt-16 lg:mt-0 relative">
        {children}
      </main>
    </div>
  );
}