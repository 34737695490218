import React, { useState } from 'react';
import { useAgents } from '../hooks/useAgents';
import { useSales } from '../hooks/useSales';
import { Users, Plus, Search, Mail, Phone, Calendar, Edit, Trash2, ChevronRight, LayoutGrid, Table } from 'lucide-react';
import AgentForm from '../components/AgentForm';
import Avatar from '../components/ui/Avatar';
import { Link } from 'react-router-dom';
import { formatCurrency, formatEuro } from '../utils/format';
import { formatDateDisplay } from '../utils/date';
import BlurredNumber from '../components/ui/BlurredNumber';
import { motion } from 'framer-motion';

export default function Agents() {
  const { agents, loading, error, deleteAgent, addAgent, updateAgent } = useAgents();
  const { sales, calculateCommission } = useSales();
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddAgent, setShowAddAgent] = useState(false);
  const [editingAgent, setEditingAgent] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<'grid' | 'table'>('grid');

  // Calculate agent stats
  const agentsWithStats = agents.map(agent => {
    const agentSales = sales.filter(sale => 
      sale.agentId === agent.id || sale.referrerId === agent.id
    );

    const stats = agentSales.reduce((acc, sale) => {
      const commission = calculateCommission(sale);
      if (sale.agentId === agent.id) {
        acc.agentCommission += commission.agent;
        acc.agencyCommission += commission.agency;
      } else if (sale.referrerId === agent.id && commission.referrer) {
        acc.agentCommission += commission.referrer;
      }
      return acc;
    }, {
      salesCount: agentSales.length,
      agentCommission: 0,
      agencyCommission: 0,
      lastSaleDate: agentSales.length > 0 ? 
        agentSales.sort((a, b) => new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime())[0].saleDate : 
        null
    });

    return {
      ...agent,
      stats
    };
  });

  // Filter agents based on search query
  const filteredAgents = agentsWithStats.filter(agent => {
    if (!searchQuery) return true;
    
    const query = searchQuery.toLowerCase();
    return (
      agent.name.toLowerCase().includes(query) ||
      agent.email.toLowerCase().includes(query) ||
      (agent.phone && agent.phone.toLowerCase().includes(query))
    );
  });

  const handleDelete = async (id: string) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet agent ?')) {
      await deleteAgent(id);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-black">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#0a84ff]"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <div className="bg-[#1c1c1e]/90 backdrop-blur-xl sticky top-0 z-50 w-full border-b border-[#2c2c2e]">
        <div className=" mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                <Users className="w-6 h-6 text-[#0a84ff]" />
              </div>
              <div>
                <h1 className="text-[22px] font-semibold text-white">Agents</h1>
                <p className="text-[13px] text-[#98989f]">
                  {agents.length} agents • {sales.length} ventes
                </p>
              </div>
            </div>
            <div className="flex bg-[#2c2c2e] rounded-xl p-1">
              <button
                onClick={() => setViewMode('grid')}
                className={`p-2 rounded-lg transition-colors ${
                  viewMode === 'grid'
                    ? 'bg-[#0a84ff] text-white'
                    : 'text-[#98989f] hover:text-white'
                }`}
              >
                <LayoutGrid className="w-5 h-5" />
              </button>
              <button
                onClick={() => setViewMode('table')}
                className={`p-2 rounded-lg transition-colors ${
                  viewMode === 'table'
                    ? 'bg-[#0a84ff] text-white'
                    : 'text-[#98989f] hover:text-white'
                }`}
              >
                <Table className="w-5 h-5" />
              </button>
            </div>
            <button
              onClick={() => setShowAddAgent(true)}
              className="px-4 py-2 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors flex items-center gap-2"
            >
              <Plus className="w-5 h-5" />
              Nouvel agent
            </button>
          </div>
        </div>

        <div className=" mx-auto px-4 py-3 border-t border-[#2c2c2e]">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
            <input
              type="text"
              placeholder="Rechercher un agent..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 bg-[#2c2c2e] text-white placeholder-[#98989f] rounded-lg focus:ring-2 focus:ring-[#0a84ff] border-none"
            />
          </div>
        </div>
      </div>

      {/* Agents List */}
      {viewMode === 'grid' ? (
        <div className="max-w-lg mx-auto px-4 py-4 space-y-4">
        {filteredAgents.map((agent) => (
          <div
            key={agent.id}
            className="bg-[#2c2c2e] rounded-xl overflow-hidden hover:bg-[#3a3a3c] transition-all duration-200"
          >
            <div className="p-4">
              <div className="flex items-center gap-4">
                <Avatar name={agent.name} size="lg" />
                <div className="flex-1 min-w-0">
                  <Link 
                    to={`/agents/${agent.id}`}
                    className="text-[17px] font-semibold text-white hover:text-[#0a84ff] transition-colors"
                  >
                    {agent.name}
                  </Link>
                  <div className="mt-1 space-y-1">
                    <div className="flex items-center gap-2 text-[13px] text-[#98989f]">
                      <Mail className="w-4 h-4" />
                      <span className="truncate">{agent.email}</span>
                    </div>
                    {agent.phone && (
                      <div className="flex items-center gap-2 text-[13px] text-[#98989f]">
                        <Phone className="w-4 h-4" />
                        <span>{agent.phone}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="bg-[#0a84ff]/10 text-[#0a84ff] text-2xl font-bold px-4 py-2 rounded-lg">
                  {agent.stats.salesCount}
                </div>
              </div>

              <div className="mt-4 grid grid-cols-2 gap-3">
                <div className="p-3 bg-[#26382f] rounded-lg">
                  <div className="text-[13px] text-[#98989f] mb-1">Commission agent</div>
                  <div className="text-[17px] font-bold text-[#30d158]">
                    <BlurredNumber value={formatCurrency(agent.stats.agentCommission)} />
                  </div>
                  <div className="text-[13px] text-[#98989f] mt-1">
                    <BlurredNumber value={formatEuro(agent.stats.agentCommission)} />
                  </div>
                </div>

                <div className="p-3 bg-[#2d2a3e] rounded-lg">
                  <div className="text-[13px] text-[#98989f] mb-1">Commission agence</div>
                  <div className="text-[17px] font-bold text-[#bf5af2]">
                    <BlurredNumber value={formatCurrency(agent.stats.agencyCommission)} />
                  </div>
                  <div className="text-[13px] text-[#98989f] mt-1">
                    <BlurredNumber value={formatEuro(agent.stats.agencyCommission)} />
                  </div>
                </div>
              </div>
            </div>

            <div className="px-4 py-3 border-t border-[#3a3a3c] flex items-center justify-between">
              <div className="flex items-center gap-2 text-[13px] text-[#98989f]">
                <Calendar className="w-4 h-4" />
                {agent.stats.lastSaleDate ? (
                  <span>Dernière vente : {formatDateDisplay(agent.stats.lastSaleDate)}</span>
                ) : (
                  <span>Aucune vente</span>
                )}
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setEditingAgent(agent.id)}
                  className="p-2 text-[#0a84ff] hover:bg-[#0a84ff]/10 rounded-lg transition-colors"
                >
                  <Edit className="w-5 h-5" />
                </button>
                <button
                  onClick={() => handleDelete(agent.id)}
                  className="p-2 text-[#ff453a] hover:bg-[#ff453a]/10 rounded-lg transition-colors"
                >
                  <Trash2 className="w-5 h-5" />
                </button>
                <Link
                  to={`/agents/${agent.id}`}
                  className="p-2 text-[#98989f] hover:bg-[#3a3a3c] rounded-lg transition-colors"
                >
                  <ChevronRight className="w-5 h-5" />
                </Link>
              </div>
            </div>
          </div>
        ))}
        </div>
      ) : (
        <div className="max-w-7xl mx-auto px-4 py-4">
          <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-[#3a3a3c]">
                    <th className="text-left p-4 text-[#98989f] font-medium">Agent</th>
                    <th className="text-left p-4 text-[#98989f] font-medium">Ventes</th>
                    <th className="text-left p-4 text-[#98989f] font-medium">Commission agent</th>
                    <th className="text-left p-4 text-[#98989f] font-medium">Commission agence</th>
                    <th className="text-left p-4 text-[#98989f] font-medium">Dernière vente</th>
                    <th className="text-left p-4 text-[#98989f] font-medium">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAgents.map((agent) => (
                    <tr key={agent.id} className="border-b border-[#3a3a3c] hover:bg-[#3a3a3c] transition-colors">
                      <td className="p-4">
                        <Link 
                          to={`/agents/${agent.id}`}
                          className="block group"
                        >
                          <p className="text-white font-medium group-hover:text-[#0a84ff] transition-colors">
                            {agent.name}
                          </p>
                          <p className="text-[#98989f] text-sm">{agent.email}</p>
                          {agent.phone && (
                            <p className="text-[#98989f] text-sm">{agent.phone}</p>
                          )}
                        </Link>
                      </td>
                      <td className="p-4">
                        <p className="text-white">{agent.stats.salesCount}</p>
                      </td>
                      <td className="p-4">
                        <p className="text-white">
                          <BlurredNumber value={formatCurrency(agent.stats.agentCommission)} />
                        </p>
                      </td>
                      <td className="p-4">
                        <p className="text-white">
                          <BlurredNumber value={formatCurrency(agent.stats.agencyCommission)} />
                        </p>
                      </td>
                      <td className="p-4">
                        <div className="flex items-center gap-2 text-[#98989f]">
                          <Calendar className="w-4 h-4" />
                          <span>
                            {agent.stats.lastSaleDate 
                              ? formatDateDisplay(agent.stats.lastSaleDate)
                              : 'Aucune vente'
                            }
                          </span>
                        </div>
                      </td>
                      <td className="p-4">
                        <div className="flex items-center gap-2">
                          <button
                            onClick={() => setEditingAgent(agent.id)}
                            className="p-2 text-[#0a84ff] hover:bg-[#0a84ff]/10 rounded-lg transition-colors"
                          >
                            <Edit className="w-5 h-5" />
                          </button>
                          <button
                            onClick={() => handleDelete(agent.id)}
                            className="p-2 text-[#ff453a] hover:bg-[#ff453a]/10 rounded-lg transition-colors"
                          >
                            <Trash2 className="w-5 h-5" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {filteredAgents.length === 0 && (
        <div className="text-center py-12">
          <Users className="w-12 h-12 text-[#98989f] mx-auto mb-4" />
          <p className="text-[#98989f]">Aucun agent trouvé</p>
        </div>
      )}

      {/* Bottom Safe Area */}
      <div className="h-8" />

      {/* Add/Edit Agent Modal */}
      {(showAddAgent || editingAgent) && (
        <AgentForm
          onSubmit={async (agentData) => {
            try {
              if (editingAgent) {
                await updateAgent(editingAgent, agentData);
                showToast('success', 'Agent modifié avec succès');
              } else {
                await addAgent(agentData);
                showToast('success', 'Agent ajouté avec succès');
              }
              setShowAddAgent(false);
              setEditingAgent(null);
            } catch (err) {
              console.error('Error saving agent:', err);
              throw err;
            }
          }}
          onClose={() => {
            setShowAddAgent(false);
            setEditingAgent(null);
          }}
          initialData={editingAgent ? agents.find(a => a.id === editingAgent) : undefined}
          createAccount={!editingAgent}
          agents={agents}
        />
      )}
    </div>
  );
}