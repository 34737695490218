import React, { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSales } from '../hooks/useSales';
import { useAgents } from '../hooks/useAgents';
import { useAuth } from '../hooks/useAuth';
import SaleForm from '../components/SaleForm';
import { generateSaleReport } from '../services/pdfService';
import { motion } from 'framer-motion';
import { MessageSquare, Share2, Building, Calendar, DollarSign, FileText, Edit, Trash2, Gift, Users, ChevronLeft, Check, Clock, CreditCard, FileCheck, AlertTriangle, AppleIcon as WhatsAppIcon, MessageCircleIcon as MessageIcon } from 'lucide-react';
import { formatCurrency, formatEuro } from '../utils/format';
import { formatDateDisplay } from '../utils/date';
import BlurredNumber from '../components/ui/BlurredNumber';
import Avatar from '../components/ui/Avatar';

export default function SaleDetails() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { sales, calculateCommission, updateSale, deleteSale } = useSales();
  const { agents } = useAgents();
  const { user } = useAuth();
  const [showEditForm, setShowEditForm] = useState(false);

  const sale = sales.find((s) => s.id === id);
  if (!sale) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="text-center">
          <AlertTriangle className="w-12 h-12 text-[#ff453a] mx-auto mb-4" />
          <p className="text-[#98989f]">Vente non trouvée</p>
        </div>
      </div>
    );
  }

  const agent = agents.find((a) => a.id === sale.agentId);
  const referrer = sale.referrerId ? agents.find((a) => a.id === sale.referrerId) : null;
  const commission = calculateCommission(sale);

  const handleDelete = async () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette vente ?')) {
      try {
        await deleteSale(sale.id);
        navigate('/sales');
      } catch (error) {
        console.error('Error deleting sale:', error);
      }
    }
  };

  const handleGeneratePDF = () => {
    try {
      const doc = generateSaleReport(sale, agent, referrer);
      const fileName = `vente-${sale.project.toLowerCase()}-${sale.unitNumber}-${
        new Date().toISOString().split('T')[0]
      }.pdf`;
      doc.save(fileName);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const handlePaymentStatusChange = async (type: 'eoi' | 'downPayment' | 'spa', status: boolean) => {
    try {
      await updateSale(sale.id, {
        client: {
          ...sale.client,
          payments: {
            ...sale.client.payments,
            [type]: {
              status,
              date: status ? new Date().toISOString() : undefined
            }
          }
        }
      });
    } catch (error) {
      console.error('Error updating payment status:', error);
    }
  };

  const handleCommissionStatusChange = async (type: 'developer' | 'agent' | 'referrer' | 'bonus', status: boolean) => {
    try {
      await updateSale(sale.id, {
        paymentStatus: {
          ...sale.paymentStatus,
          [type]: status
        }
      });
    } catch (error) {
      console.error('Error updating commission status:', error);
    }
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <div className="bg-[#1c1c1e] sticky top-0 z-50 w-full border-b border-[#2c2c2e]">
        <div className="max-w-lg mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <button
                onClick={() => navigate(-1)}
                className="w-8 h-8 flex items-center justify-center rounded-xl hover:bg-[#2c2c2e] transition-colors"
              >
                <ChevronLeft className="w-5 h-5 text-[#0a84ff]" />
              </button>
              <div>
                <h1 className="text-[17px] font-semibold text-white">{sale.project}</h1>
                <p className="text-[13px] text-[#98989f]">Unité {sale.unitNumber}</p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={handleGeneratePDF}
                className="p-2 text-[#0a84ff] hover:bg-[#0a84ff]/10 rounded-xl transition-colors"
              >
                <FileText className="w-5 h-5" />
              </button>
              <button
                onClick={() => setShowEditForm(true)}
                className="p-2 text-[#0a84ff] hover:bg-[#0a84ff]/10 rounded-xl transition-colors"
              >
                <Edit className="w-5 h-5" />
              </button>
              <button
                onClick={handleDelete}
                className="p-2 text-[#ff453a] hover:bg-[#ff453a]/10 rounded-xl transition-colors"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-lg mx-auto px-4 py-4 space-y-4">

        {/* Client Info */}
        <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
          <div className="p-4">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-3">
              <Users className="w-5 h-5 text-[#0a84ff]" />
              <h2 className="text-[17px] font-medium text-white">Informations client</h2>
              </div>
              {sale.client.email && (
                <Link
                  to={`/clients/${sale.client.email}`}
                  className="px-4 py-2 bg-[#0a84ff]/10 text-[#0a84ff] rounded-xl hover:bg-[#0a84ff]/20 transition-colors flex items-center gap-2"
                >
                  <Users className="w-4 h-4" />
                  <span className="text-[13px] font-medium">Voir le profil</span>
                </Link>
              )}
            </div>
            
            <div className="space-y-4">
              <div>
                <p className="text-[13px] text-[#98989f] mb-1">Nom du client</p>
                <p className="text-[17px] text-white">{sale.client.name || 'Non renseigné'}</p>
              </div>
              
              {sale.client.email && (
                <div>
                  <p className="text-[13px] text-[#98989f] mb-1">Email</p>
                  <a 
                    href={`mailto:${sale.client.email}`}
                    className="text-[17px] text-[#0a84ff] hover:underline"
                  >
                    {sale.client.email}
                  </a>
                </div>
              )}
              
              {sale.client.phone && (
                <div>
                  <p className="text-[13px] text-[#98989f] mb-1">Téléphone</p>
                  <a 
                    href={`tel:${sale.client.phone}`}
                    className="text-[17px] text-[#0a84ff] hover:underline"
                  >
                    {sale.client.phone}
                  </a>
                </div>
              )}

              <div>
                <p className="text-[13px] text-[#98989f] mb-1">Source du lead</p>
                <p className="text-[17px] text-white">{sale.leadSource}</p>
                {sale.client.email && (
                  <div className="mt-4 p-3 bg-[#3a3a3c] rounded-xl">
                    <div className="flex items-center gap-2 mb-2">
                      <DollarSign className="w-4 h-4 text-[#30d158]" />
                      <p className="text-[13px] text-[#98989f]">Statistiques client</p>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <p className="text-[13px] text-[#98989f] mb-1">Historique d'achats</p>
                        <p className="text-[15px] text-white">
                          {sales.filter(s => s.client.email === sale.client.email).length} ventes
                        </p>
                      </div>
                      <div>
                        <p className="text-[13px] text-[#98989f] mb-1">Commission générée</p>
                        <p className="text-[15px] text-white">
                          <BlurredNumber value={formatCurrency(
                            sales
                              .filter(s => s.client.email === sale.client.email)
                              .reduce((sum, s) => {
                                const comm = calculateCommission(s);
                                return sum + comm.agency + (s.bonus ? comm.bonus.agency : 0);
                              }, 0)
                          )} />
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Payment Status */}
        <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
          <div className="p-4">
            <div className="flex items-center gap-3 mb-4">
              <CreditCard className="w-5 h-5 text-[#0a84ff]" />
              <h2 className="text-[17px] font-medium text-white">Statut des paiements</h2>
            </div>
            
            <div className="space-y-4">
              {/* EOI */}
              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <FileCheck className="w-4 h-4 text-[#0a84ff]" />
                    <p className="text-[15px] text-white">EOI</p>
                  </div>
                  <div className="flex items-center gap-4">
                    {sale.client.payments?.eoi?.date && (
                      <span className="text-[13px] text-[#98989f]">
                        {formatDateDisplay(sale.client.payments.eoi.date)}
                      </span>
                    )}
                    <button
                      onClick={() => handlePaymentStatusChange('eoi', !sale.client.payments?.eoi?.status)}
                      className={`px-3 py-1 rounded-xl text-[13px] font-medium flex items-center gap-2 ${
                        sale.client.payments?.eoi?.status
                          ? 'bg-[#26382f] text-[#30d158]'
                          : 'bg-[#3a2c29] text-[#ff453a]'
                      }`}
                    >
                      {sale.client.payments?.eoi?.status ? (
                        <>
                          <Check className="w-4 h-4" />
                          <span>Reçu</span>
                        </>
                      ) : (
                        <>
                          <Clock className="w-4 h-4" />
                          <span>En attente</span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              {/* Down Payment */}
              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <DollarSign className="w-4 h-4 text-[#0a84ff]" />
                    <p className="text-[15px] text-white">Down Payment</p>
                  </div>
                  <div className="flex items-center gap-4">
                    {sale.client.payments?.downPayment?.date && (
                      <span className="text-[13px] text-[#98989f]">
                        {formatDateDisplay(sale.client.payments.downPayment.date)}
                      </span>
                    )}
                    <button
                      onClick={() => handlePaymentStatusChange('downPayment', !sale.client.payments?.downPayment?.status)}
                      className={`px-3 py-1 rounded-xl text-[13px] font-medium flex items-center gap-2 ${
                        sale.client.payments?.downPayment?.status
                          ? 'bg-[#26382f] text-[#30d158]'
                          : 'bg-[#3a2c29] text-[#ff453a]'
                      }`}
                    >
                      {sale.client.payments?.downPayment?.status ? (
                        <>
                          <Check className="w-4 h-4" />
                          <span>Reçu</span>
                        </>
                      ) : (
                        <>
                          <Clock className="w-4 h-4" />
                          <span>En attente</span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              {/* SPA */}
              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2">
                    <FileText className="w-4 h-4 text-[#0a84ff]" />
                    <p className="text-[15px] text-white">SPA</p>
                  </div>
                  <div className="flex items-center gap-4">
                    {sale.client.payments?.spa?.date && (
                      <span className="text-[13px] text-[#98989f]">
                        {formatDateDisplay(sale.client.payments.spa.date)}
                      </span>
                    )}
                    <button
                      onClick={() => handlePaymentStatusChange('spa', !sale.client.payments?.spa?.status)}
                      className={`px-3 py-1 rounded-xl text-[13px] font-medium flex items-center gap-2 ${
                        sale.client.payments?.spa?.status
                          ? 'bg-[#26382f] text-[#30d158]'
                          : 'bg-[#3a2c29] text-[#ff453a]'
                      }`}
                    >
                      {sale.client.payments?.spa?.status ? (
                        <>
                          <Check className="w-4 h-4" />
                          <span>Signé</span>
                        </>
                      ) : (
                        <>
                          <Clock className="w-4 h-4" />
                          <span>En attente</span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Sale Info */}
        <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
          <div className="p-4">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-3">
                <Building className="w-5 h-5 text-[#0a84ff]" />
                <h2 className="text-[17px] font-medium text-white">Détails de la vente</h2>
              </div>
              <div className="flex items-center gap-2">
                <Calendar className="w-4 h-4 text-[#98989f]" />
                <span className="text-[13px] text-[#98989f]">
                  {formatDateDisplay(sale.saleDate)}
                </span>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <p className="text-[13px] text-[#98989f] mb-1">Prix du bien</p>
                <p className="text-[28px] font-bold text-white">
                  <BlurredNumber value={formatCurrency(sale.propertyPrice)} />
                </p>
                <p className="text-[13px] text-[#98989f]">
                  <BlurredNumber value={formatEuro(sale.propertyPrice)} />
                </p>
              </div>

              <div>
                <p className="text-[13px] text-[#98989f] mb-1">Commission promoteur</p>
                <p className="text-[17px] font-bold text-white">
                  <BlurredNumber value={formatCurrency(sale.developerCommission)} />
                </p>
                <p className="text-[13px] text-[#98989f]">
                  {sale.developerCommissionPercentage}%
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Commission Status */}
        <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
          <div className="p-4">
            <div className="flex items-center gap-3 mb-4">
              <DollarSign className="w-5 h-5 text-[#0a84ff]" />
              <h2 className="text-[17px] font-medium text-white">Statut des commissions</h2>
            </div>
            
            <div className="space-y-4">
              {/* Developer Commission */}
              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <div className="flex items-center justify-between mb-2">
                  <div>
                    <p className="text-[15px] text-white">Commission promoteur</p>
                    <p className="text-[17px] font-bold text-white">
                      <BlurredNumber value={formatCurrency(sale.developerCommission)} />
                    </p>
                  </div>
                  <button
                    onClick={() => handleCommissionStatusChange('developer', !sale.paymentStatus.developer)}
                    className={`px-3 py-1 rounded-xl text-[13px] font-medium flex items-center gap-2 ${
                      sale.paymentStatus.developer
                        ? 'bg-[#26382f] text-[#30d158]'
                        : 'bg-[#3a2c29] text-[#ff453a]'
                    }`}
                  >
                    {sale.paymentStatus.developer ? (
                      <>
                        <Check className="w-4 h-4" />
                        <span>Reçue</span>
                      </>
                    ) : (
                      <>
                        <Clock className="w-4 h-4" />
                        <span>En attente</span>
                      </>
                    )}
                  </button>
                </div>
              </div>

              {/* Agent Commission */}
              {agent && (
                <div className="p-4 bg-[#3a3a3c] rounded-xl">
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center gap-3">
                      <Avatar name={agent.name} size="sm" />
                      <div>
                        <Link
                          to={`/agents/${agent.id}`}
                          className="text-[15px] font-medium text-white hover:text-[#0a84ff] transition-colors"
                        >
                          {agent.name}
                        </Link>
                        <p className="text-[13px] text-[#98989f]">Agent principal</p>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="text-[17px] font-bold text-white">
                        <BlurredNumber value={formatCurrency(commission.agent)} />
                      </p>
                      {sale.bonus && (
                        <div className="flex items-center gap-1 mt-1 text-[13px] text-[#bf5af2]">
                          <Gift className="w-4 h-4" />
                          +<BlurredNumber value={formatCurrency(commission.bonus.agent)} />
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    onClick={() => handleCommissionStatusChange('agent', !sale.paymentStatus.agent)}
                    className={`px-3 py-1 rounded-xl text-[13px] font-medium flex items-center gap-2 ${
                      sale.paymentStatus.agent
                        ? 'bg-[#26382f] text-[#30d158]'
                        : 'bg-[#3a2c29] text-[#ff453a]'
                    }`}
                  >
                    {sale.paymentStatus.agent ? (
                      <>
                        <Check className="w-4 h-4" />
                        <span>Payée</span>
                      </>
                    ) : (
                      <>
                        <Clock className="w-4 h-4" />
                        <span>En attente</span>
                      </>
                    )}
                  </button>
                </div>
              )}

              {/* Referrer Commission */}
              {referrer && (
                <div className="p-4 bg-[#3a3a3c] rounded-xl">
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center gap-3">
                      <Avatar name={referrer.name} size="sm" />
                      <div>
                        <Link
                          to={`/agents/${referrer.id}`}
                          className="text-[15px] font-medium text-white hover:text-[#0a84ff] transition-colors"
                        >
                          {referrer.name}
                        </Link>
                        <p className="text-[13px] text-[#98989f]">Parrain</p>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="text-[17px] font-bold text-white">
                        <BlurredNumber value={formatCurrency(commission.referrer || 0)} />
                      </p>
                      {sale.bonus?.secondAgentId === referrer.id && (
                        <div className="flex items-center gap-1 mt-1 text-[13px] text-[#bf5af2]">
                          <Gift className="w-4 h-4" />
                          +<BlurredNumber value={formatCurrency(commission.bonus.referrer)} />
                        </div>
                      )}
                    </div>
                  </div>
                  <button
                    onClick={() => handleCommissionStatusChange('referrer', !sale.paymentStatus.referrer)}
                    className={`px-3 py-1 rounded-xl text-[13px] font-medium flex items-center gap-2 ${
                      sale.paymentStatus.referrer
                        ? 'bg-[#26382f] text-[#30d158]'
                        : 'bg-[#3a2c29] text-[#ff453a]'
                    }`}
                  >
                    {sale.paymentStatus.referrer ? (
                      <>
                        <Check className="w-4 h-4" />
                        <span>Payée</span>
                      </>
                    ) : (
                      <>
                        <Clock className="w-4 h-4" />
                        <span>En attente</span>
                      </>
                    )}
                  </button>
                </div>
              )}

              {/* Agency Commission */}
              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <div className="flex items-center justify-between mb-2">
                  <div>
                    <p className="text-[15px] text-white">Commission agence</p>
                    <p className="text-[17px] font-bold text-white">
                      <BlurredNumber value={formatCurrency(commission.agency)} />
                    </p>
                  </div>
                  {sale.bonus && (
                    <div className="flex items-center gap-1 text-[13px] text-[#bf5af2]">
                      <Gift className="w-4 h-4" />
                      +<BlurredNumber value={formatCurrency(commission.bonus.agency)} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Safe Area */}
        <div className="h-8" />
      </div>

      {/* Edit Sale Modal */}
      {showEditForm && (
        <SaleForm
          initialData={sale}
          onSubmit={async (updatedSale) => {
            await updateSale(sale.id, updatedSale);
            setShowEditForm(false);
          }}
          onClose={() => setShowEditForm(false)}
        />
      )}
    </div>
  );
}