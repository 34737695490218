import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, Clock, Gift, TrendingUp, Users } from 'lucide-react';
import { useSales } from '../../hooks/useSales';
import { formatCurrency, formatEuro } from '../../utils/format';
import BlurredNumber from '../../components/ui/BlurredNumber';

export default function KPICards() {
  const { sales, calculateCommission } = useSales();
  
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const startDate = new Date(2024, 6); // July 2024
  const monthsSinceStart = Math.max(
    1,
    (currentYear - 2024) * 12 + (currentMonth - 6) + 1
  );

  const kpis = sales.reduce(
    (acc, sale) => {
      const commission = calculateCommission(sale);
      const saleDate = new Date(sale.createdAt);
      const isCurrentMonth =
        saleDate.getMonth() === currentMonth &&
        saleDate.getFullYear() === currentYear;

      // Calculate total agency commission
      acc.totalAgencyCommission += commission.agency;
      
      // Calculate total agent commission
      acc.totalAgentCommission += commission.agent;
      if (sale.referrerId) {
        acc.totalAgentCommission += commission.referrer || 0;
      }

      if (!sale.paymentStatus.developer) {
        acc.pendingCommission += commission.agency;
      }

      if (isCurrentMonth) {
        acc.monthlyVolume += sale.propertyPrice;
        acc.monthlyCommission += commission.agency;
        acc.monthlySales += 1;
      }

      if (saleDate >= startDate) {
        acc.totalSalesCount += 1;
      }

      if (sale.bonus) {
        acc.totalBonus += commission.bonus.agency;
        if (!sale.paymentStatus.bonus) {
          acc.pendingBonus += commission.bonus.agency;
        }
      }

      return acc;
    },
    {
      totalAgencyCommission: 0,
      totalAgentCommission: 0,
      pendingCommission: 0,
      monthlyVolume: 0,
      monthlyCommission: 0,
      monthlySales: 0,
      totalSalesCount: 0,
      totalBonus: 0,
      pendingBonus: 0,
    }
  );

  const averageMonthlySales = Math.round(kpis.totalSalesCount / monthsSinceStart);

  return (
    <div className="grid grid-cols-2 gap-4">
      {/* Commission perçue */}
      <div className="bg-[#26382f] rounded-xl p-6 relative overflow-hidden group">
        <motion.div
          className="absolute inset-0 bg-gradient-to-br from-[#30d158]/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.1, 0.2, 0.1],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
        <div className="relative">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-10 h-10 rounded-xl bg-[#30d158]/10 flex items-center justify-center">
              <DollarSign className="w-6 h-6 text-[#30d158]" />
            </div>
            <p className="text-[15px] text-[#98989f]">Commission perçue</p>
          </div>
          <p className="text-[22px] font-bold text-[#30d158] mb-2">
            <BlurredNumber value={formatCurrency(kpis.totalAgencyCommission)} />
          </p>
          <p className="text-[13px] text-[#98989f]">
            <BlurredNumber value={formatEuro(kpis.totalAgencyCommission)} />
          </p>
        </div>
      </div>

      {/* Commission en attente */}
      <div className="bg-[#3a2c29] rounded-xl p-6 relative overflow-hidden group">
        <motion.div
          className="absolute inset-0 bg-gradient-to-br from-[#ff453a]/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.1, 0.2, 0.1],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
        <div className="relative">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-10 h-10 rounded-xl bg-[#ff453a]/10 flex items-center justify-center">
              <Clock className="w-6 h-6 text-[#ff453a]" />
            </div>
            <p className="text-[15px] text-[#98989f]">Commission en attente</p>
          </div>
          <p className="text-[22px] font-bold text-[#ff453a] mb-2">
            <BlurredNumber value={formatCurrency(kpis.pendingCommission)} />
          </p>
          <p className="text-[13px] text-[#98989f]">
            <BlurredNumber value={formatEuro(kpis.pendingCommission)} />
          </p>
        </div>
      </div>

      {/* Commission agents */}
      <div className="bg-[#2d2a3e] rounded-xl p-6 relative overflow-hidden group">
        <motion.div
          className="absolute inset-0 bg-gradient-to-br from-[#bf5af2]/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.1, 0.2, 0.1],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
        <div className="relative">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-10 h-10 rounded-xl bg-[#bf5af2]/10 flex items-center justify-center">
              <Users className="w-6 h-6 text-[#bf5af2]" />
            </div>
            <p className="text-[15px] text-[#98989f]">Commission agents</p>
          </div>
          <p className="text-[22px] font-bold text-[#bf5af2] mb-2">
            <BlurredNumber value={formatCurrency(kpis.totalAgentCommission)} />
          </p>
          <p className="text-[13px] text-[#98989f]">
            <BlurredNumber value={formatEuro(kpis.totalAgentCommission)} />
          </p>
        </div>
      </div>

      {/* Nombre de ventes */}
      <div className="bg-[#1c3a4f] rounded-xl p-6 relative overflow-hidden group">
        <motion.div
          className="absolute inset-0 bg-gradient-to-br from-[#0a84ff]/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.1, 0.2, 0.1],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
        <div className="relative">
          <div className="flex items-center gap-3 mb-4">
            <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
              <TrendingUp className="w-6 h-6 text-[#0a84ff]" />
            </div>
            <p className="text-[15px] text-[#98989f]">Nombre de ventes</p>
          </div>
          <p className="text-[22px] font-bold text-[#0a84ff] mb-2">
            {kpis.totalSalesCount} ventes
          </p>
          <p className="text-[13px] text-[#98989f]">
            Depuis juillet 2024
          </p>
        </div>
      </div>
    </div>
  );
}