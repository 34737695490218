import React from 'react';
import { motion } from 'framer-motion';

export default function LoadingScreen() {
  return (
    <div className="fixed inset-0 bg-black flex flex-col items-center justify-center z-50">
      {/* Logo */}
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ 
          opacity: 1, 
          scale: [0.8, 1.1, 1],
          rotate: [0, 10, 0, -10, 0]
        }}
        transition={{ 
          duration: 1.5,
          ease: "easeInOut",
          times: [0, 0.5, 0.8, 0.9, 1]
        }}
        className="mb-8"
      >
        <img
          src="https://palmdubai.fr/templates/Default/img/logo_white.svg"
          alt="Palm Dubai Logo"
          className="w-32 h-auto"
        />
      </motion.div>

      {/* Loading Bar */}
      <div className="w-48 h-1 bg-[#2c2c2e] rounded-full overflow-hidden">
        <motion.div
          className="h-full bg-[#0a84ff] rounded-full origin-left"
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          transition={{ 
            duration: 1.5,
            ease: [0.34, 1.56, 0.64, 1]
          }}
        />
      </div>

      {/* Loading Text */}
      <motion.p
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className="mt-4 text-[#98989f] text-sm"
      >
        Chargement...
      </motion.p>
    </div>
  );
}