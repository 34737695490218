import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Building, Search, Plus, Calendar, ChevronRight, Upload, X } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { useToast } from '../../contexts/ToastContext';
import { formatDateDisplay } from '../../utils/date';
import type { ConstructionUpdate, InvestorProperty } from '../../types';

export default function ConstructionUpdates() {
  const { showToast } = useToast();
  const [updates, setUpdates] = useState<ConstructionUpdate[]>([]);
  const [properties, setProperties] = useState<Record<string, InvestorProperty>>({});
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    description: '',
    completionPercentage: 0,
    images: [] as File[]
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get all updates
        const { data: updatesData, error: updatesError } = await supabase
          .from('construction_updates')
          .select('*')
          .order('created_at', { ascending: false });

        if (updatesError) throw updatesError;

        // Get all properties referenced in updates
        const propertyIds = [...new Set(updatesData?.map(u => u.propertyId) || [])];
        const { data: propertiesData, error: propertiesError } = await supabase
          .from('investor_properties')
          .select('*')
          .in('id', propertyIds);

        if (propertiesError) throw propertiesError;

        // Create properties lookup object
        const propertiesLookup = propertiesData?.reduce((acc, property) => {
          acc[property.id] = property;
          return acc;
        }, {} as Record<string, InvestorProperty>);

        setUpdates(updatesData || []);
        setProperties(propertiesLookup || {});
      } catch (error) {
        console.error('Error fetching updates:', error);
        showToast('error', 'Erreur lors du chargement des mises à jour');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [showToast]);

  const handleAddUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedProperty) return;

    try {
      // Upload images first
      const imageUrls = await Promise.all(
        formData.images.map(async (file) => {
          const { data, error } = await supabase.storage
            .from('construction-updates')
            .upload(`${selectedProperty}/${Date.now()}-${file.name}`, file);

          if (error) throw error;
          return supabase.storage.from('construction-updates').getPublicUrl(data.path).data.publicUrl;
        })
      );

      // Create update
      const { error } = await supabase
        .from('construction_updates')
        .insert({
          propertyId: selectedProperty,
          description: formData.description,
          completionPercentage: formData.completionPercentage,
          images: imageUrls
        });

      if (error) throw error;

      showToast('success', 'Mise à jour ajoutée avec succès');
      setShowAddModal(false);
      setFormData({ description: '', completionPercentage: 0, images: [] });
    } catch (error) {
      console.error('Error adding update:', error);
      showToast('error', 'Erreur lors de l\'ajout de la mise à jour');
    }
  };

  const filteredUpdates = updates.filter(update => {
    if (!searchQuery) return true;

    const property = properties[update.propertyId];
    const query = searchQuery.toLowerCase();
    return (
      property?.project.toLowerCase().includes(query) ||
      property?.unitNumber.toLowerCase().includes(query) ||
      update.description.toLowerCase().includes(query)
    );
  });

  if (loading) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#0a84ff]"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <div className="bg-[#1c1c1e]/90 backdrop-blur-xl sticky top-0 z-50 w-full border-b border-[#2c2c2e]">
        <div className="max-w-7xl mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                <Building className="w-6 h-6 text-[#0a84ff]" />
              </div>
              <div>
                <h1 className="text-[22px] font-semibold text-white">Suivi de chantier</h1>
                <p className="text-[13px] text-[#98989f]">
                  {updates.length} mises à jour
                </p>
              </div>
            </div>
            <button
              onClick={() => setShowAddModal(true)}
              className="px-4 py-2 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors flex items-center gap-2"
            >
              <Plus className="w-5 h-5" />
              Nouvelle mise à jour
            </button>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 py-3 border-t border-[#2c2c2e]">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
            <input
              type="text"
              placeholder="Rechercher une mise à jour..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 bg-[#2c2c2e] text-white placeholder-[#98989f] rounded-lg focus:ring-2 focus:ring-[#0a84ff] border-none"
            />
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-4">
        <div className="space-y-4">
          {filteredUpdates.map(update => {
            const property = properties[update.propertyId];
            if (!property) return null;

            return (
              <div key={update.id} className="bg-[#2c2c2e] rounded-xl overflow-hidden">
                <div className="p-4">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <h3 className="text-[17px] font-medium text-white">{property.project}</h3>
                      <p className="text-[15px] text-[#98989f]">Unité {property.unitNumber}</p>
                    </div>
                    <div className="px-3 py-1 bg-[#3a3a3c] rounded-xl">
                      <span className="text-[13px] text-[#98989f]">
                        {update.completionPercentage}% complété
                      </span>
                    </div>
                  </div>

                  <p className="text-[15px] text-white mb-4">{update.description}</p>

                  {update.images.length > 0 && (
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 mb-4">
                      {update.images.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={`Update ${index + 1}`}
                          className="w-full h-32 object-cover rounded-lg"
                        />
                      ))}
                    </div>
                  )}

                  <div className="flex items-center justify-between pt-4 border-t border-[#3a3a3c]">
                    <div className="flex items-center gap-2">
                      <Calendar className="w-4 h-4 text-[#98989f]" />
                      <span className="text-[13px] text-[#98989f]">
                        {formatDateDisplay(update.createdAt)}
                      </span>
                    </div>
                    <button className="p-2 text-[#0a84ff] hover:bg-[#0a84ff]/10 rounded-lg transition-colors">
                      <ChevronRight className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}

          {filteredUpdates.length === 0 && (
            <div className="text-center py-12">
              <Building className="w-12 h-12 text-[#98989f] mx-auto mb-4" />
              <p className="text-[#98989f]">Aucune mise à jour trouvée</p>
            </div>
          )}
        </div>
      </div>

      {/* Add Update Modal */}
      {showAddModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            className="bg-[#1c1c1e] rounded-2xl w-full max-w-lg m-4"
          >
            <div className="p-6 border-b border-[#2c2c2e]">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <Building className="w-6 h-6 text-[#0a84ff]" />
                  <h2 className="text-[22px] font-semibold text-white">Nouvelle mise à jour</h2>
                </div>
                <button
                  onClick={() => setShowAddModal(false)}
                  className="p-2 hover:bg-[#2c2c2e] rounded-xl transition-colors"
                >
                  <X className="w-5 h-5 text-[#98989f]" />
                </button>
              </div>
            </div>

            <form onSubmit={handleAddUpdate} className="p-6 space-y-6">
              <div>
                <label className="block text-[13px] text-[#98989f] mb-2">
                  Bien
                </label>
                <select
                  value={selectedProperty || ''}
                  onChange={(e) => setSelectedProperty(e.target.value)}
                  required
                  className="w-full px-4 py-3 bg-[#2c2c2e] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff]"
                >
                  <option value="">Sélectionner un bien</option>
                  {Object.values(properties).map(property => (
                    <option key={property.id} value={property.id}>
                      {property.project} - Unité {property.unitNumber}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-[13px] text-[#98989f] mb-2">
                  Description
                </label>
                <textarea
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  required
                  className="w-full px-4 py-3 bg-[#2c2c2e] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] resize-none"
                  rows={4}
                />
              </div>

              <div>
                <label className="block text-[13px] text-[#98989f] mb-2">
                  Pourcentage d'avancement
                </label>
                <input
                  type="number"
                  min="0"
                  max="100"
                  value={formData.completionPercentage}
                  onChange={(e) => setFormData({ ...formData, completionPercentage: Number(e.target.value) })}
                  required
                  className="w-full px-4 py-3 bg-[#2c2c2e] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff]"
                />
              </div>

              <div>
                <label className="block text-[13px] text-[#98989f] mb-2">
                  Photos
                </label>
                <div className="relative">
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={(e) => setFormData({ ...formData, images: Array.from(e.target.files || []) })}
                    className="hidden"
                    id="images"
                  />
                  <label
                    htmlFor="images"
                    className="flex items-center justify-center gap-2 px-4 py-3 bg-[#2c2c2e] text-white rounded-xl border-2 border-dashed border-[#3a3a3c] hover:bg-[#3a3a3c] transition-colors cursor-pointer"
                  >
                    <Upload className="w-5 h-5" />
                    <span>Ajouter des photos</span>
                  </label>
                </div>
                {formData.images.length > 0 && (
                  <div className="mt-4 grid grid-cols-2 gap-2">
                    {Array.from(formData.images).map((file, index) => (
                      <div key={index} className="relative">
                        <img
                          src={URL.createObjectURL(file)}
                          alt={`Preview ${index + 1}`}
                          className="w-full h-32 object-cover rounded-lg"
                        />
                        <button
                          type="button"
                          onClick={() => setFormData({
                            ...formData,
                            images: formData.images.filter((_, i) => i !== index)
                          })}
                          className="absolute top-2 right-2 p-1 bg-red-600 text-white rounded-full"
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="flex justify-end gap-3 pt-6">
                <button
                  type="button"
                  onClick={() => setShowAddModal(false)}
                  className="px-4 py-2 bg-[#2c2c2e] text-[#98989f] rounded-xl hover:bg-[#3a3a3c] transition-colors"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors"
                >
                  Ajouter
                </button>
              </div>
            </form>
          </motion.div>
        </div>
      )}
    </div>
  );
}