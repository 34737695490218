import type { Agent, Sale, User } from '../types';
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL!;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY!;

// Initialize Supabase client
export const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true
  }
});

export async function addDemoAgent(): Promise<Agent> {
  // First create the table if it doesn't exist
  await supabase.rpc('execute_sql', {
    sql_query: `
      CREATE TABLE IF NOT EXISTS agents (
        id UUID DEFAULT uuid_generate_v4() PRIMARY KEY,
        name TEXT NOT NULL,
        email TEXT UNIQUE NOT NULL,
        phone TEXT,
        avatar TEXT,
        total_sales BIGINT DEFAULT 0,
        total_commission BIGINT DEFAULT 0,
        created_at TIMESTAMP WITH TIME ZONE DEFAULT timezone('utc', now()),
        updated_at TIMESTAMP WITH TIME ZONE DEFAULT timezone('utc', now())
      );
    `
  });

  const demoAgent = {
    name: 'Agent Demo',
    email: `demo.${Date.now()}@palmdubaiimmo.com`,
    phone: '+971501234567',
    total_sales: 0,
    total_commission: 0,
    avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=400&h=400&fit=crop',
  };

  const { data, error } = await supabase
    .from('agents')
    .insert([demoAgent])
    .select()
    .single();

  if (error) throw error;
  return data;
}

export async function addDemoSale(): Promise<Sale> {
  // First create the table if it doesn't exist
  await supabase.rpc('execute_sql', {
    sql_query: `
      CREATE TABLE IF NOT EXISTS sales (
        id UUID DEFAULT uuid_generate_v4() PRIMARY KEY,
        property_price BIGINT NOT NULL,
        developer_commission BIGINT NOT NULL,
        developer_commission_percentage INTEGER NOT NULL,
        client JSONB,
        lead_source TEXT NOT NULL,
        project TEXT NOT NULL,
        unit_number TEXT NOT NULL,
        expected_delivery_year INTEGER NOT NULL,
        agent_id UUID REFERENCES agents(id) NOT NULL,
        referrer_id UUID REFERENCES agents(id),
        status TEXT NOT NULL,
        sale_date DATE NOT NULL,
        commission_type TEXT NOT NULL,
        commission_received_date TIMESTAMP WITH TIME ZONE,
        invoice_sent_date TIMESTAMP WITH TIME ZONE,
        agent_paid_date TIMESTAMP WITH TIME ZONE,
        created_at TIMESTAMP WITH TIME ZONE DEFAULT timezone('utc', now()),
        updated_at TIMESTAMP WITH TIME ZONE DEFAULT timezone('utc', now())
      );
    `
  });

  // First get a random agent
  const { data: agents } = await supabase
    .from('agents')
    .select('id')
    .limit(1);

  if (!agents?.length) throw new Error('No agents found');

  const demoSale = {
    property_price: 1000000,
    developer_commission: 60000,
    developer_commission_percentage: 6,
    project: 'Palm Jumeirah Demo',
    unit_number: `A-${Math.floor(Math.random() * 999)}`,
    lead_source: 'Demo',
    status: 'pending',
    expected_delivery_year: new Date().getFullYear() + 1,
    client: { name: 'Client Demo' },
    agent_id: agents[0].id,
    commission_type: 'standard',
    sale_date: new Date().toISOString(),
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  };

  const { data, error } = await supabase
    .from('sales')
    .insert([demoSale])
    .select()
    .single();

  if (error) throw error;
  return data;
}

export async function addDemoUser(): Promise<User> {
  // First create the table if it doesn't exist
  await supabase.rpc('execute_sql', {
    sql_query: `
      CREATE TABLE IF NOT EXISTS users (
        id UUID DEFAULT uuid_generate_v4() PRIMARY KEY,
        name TEXT NOT NULL,
        email TEXT UNIQUE NOT NULL,
        role TEXT NOT NULL,
        password TEXT,
        phone TEXT,
        avatar TEXT,
        created_at TIMESTAMP WITH TIME ZONE DEFAULT timezone('utc', now()),
        updated_at TIMESTAMP WITH TIME ZONE DEFAULT timezone('utc', now())
      );
    `
  });

  const demoUser = {
    name: 'User Demo',
    email: `demo.${Date.now()}@palmdubaiimmo.com`,
    role: 'agent',
    password: 'demo123', // In production, this should be hashed
  };

  const { data, error } = await supabase
    .from('users')
    .insert([demoUser])
    .select()
    .single();

  if (error) throw error;
  return data;
}

const createAgentsTable = async () => {
  // Drop existing table if it exists
  await supabase.rpc('drop_agents_table', {
    sql: 'DROP TABLE IF EXISTS agents CASCADE;'
  });

  // Create new table
  const { error } = await supabase.rpc('execute_sql', {
    sql_query: `
      CREATE TABLE agents (
        id UUID DEFAULT uuid_generate_v4() PRIMARY KEY,
        name TEXT NOT NULL,
        email TEXT UNIQUE NOT NULL,
        phone TEXT,
        avatar TEXT,
        total_sales BIGINT DEFAULT 0,
        total_commission BIGINT DEFAULT 0,
        created_at TIMESTAMP WITH TIME ZONE DEFAULT timezone('utc', now()),
        updated_at TIMESTAMP WITH TIME ZONE DEFAULT timezone('utc', now())
      );
    `
  });
  return error;
};

const createSalesTable = async () => {
  // Drop existing table if it exists
  await supabase.rpc('drop_sales_table', {
    sql: 'DROP TABLE IF EXISTS sales CASCADE;'
  });

  // Create new table
  const { error } = await supabase.rpc('execute_sql', {
    sql_query: `
      CREATE TABLE sales (
        id UUID DEFAULT uuid_generate_v4() PRIMARY KEY,
        property_price BIGINT NOT NULL,
        developer_commission BIGINT NOT NULL,
        developer_commission_percentage INTEGER NOT NULL,
        client JSONB,
        lead_source TEXT NOT NULL,
        project TEXT NOT NULL,
        unit_number TEXT NOT NULL,
        expected_delivery_year INTEGER NOT NULL,
        agent_id UUID REFERENCES agents(id) NOT NULL,
        referrer_id UUID REFERENCES agents(id),
        status TEXT NOT NULL,
        sale_date DATE NOT NULL,
        commission_type TEXT NOT NULL,
        commission_received_date TIMESTAMP WITH TIME ZONE,
        invoice_sent_date TIMESTAMP WITH TIME ZONE,
        agent_paid_date TIMESTAMP WITH TIME ZONE,
        created_at TIMESTAMP WITH TIME ZONE DEFAULT timezone('utc', now()),
        updated_at TIMESTAMP WITH TIME ZONE DEFAULT timezone('utc', now())
      );
    `
  });
  return error;
};

const createSettingsTable = async () => {
  const { error } = await supabase.rpc('create_settings_table', {
    sql: `
      CREATE TABLE IF NOT EXISTS settings (
        id UUID DEFAULT uuid_generate_v4() PRIMARY KEY,
        name TEXT NOT NULL,
        value JSONB NOT NULL,
        created_at TIMESTAMP WITH TIME ZONE DEFAULT timezone('utc', now()),
        updated_at TIMESTAMP WITH TIME ZONE DEFAULT timezone('utc', now())
      );
    `
  });
  return error;
};

export async function initializeDatabase() {
  const agentsError = await createAgentsTable();
  if (agentsError) console.error('Error initializing agents table:', agentsError);

  const salesError = await createSalesTable();
  if (salesError) console.error('Error initializing sales table:', salesError);

  const settingsError = await createSettingsTable();
  if (settingsError) console.error('Error initializing settings table:', settingsError);

  // Initialize default settings if needed
  const { data: settings } = await supabase
    .from('settings')
    .select('*')
    .single();

  if (!settings) {
    await supabase.from('settings').insert([{
      name: 'general',
      value: {
        name: 'PalmCRM',
        email: 'contact@palmdubaiimmo.com',
        notifications: {
          newSale: true,
          commissionReceived: true,
          agentCreated: true,
          loginAttempt: true
        },
        emailjs: {
          serviceId: 'service_sqg5icr',
          publicKey: 'Dk34hxmb9NM4X2lte'
        }
      }
    }]);
  }
}

export function handleSupabaseError(error: any): string {
  if (error?.code === '23505') return 'A record with this value already exists';
  if (error?.code === '23503') return 'Referenced record does not exist';
  return error?.message || 'An unexpected error occurred';
}