export function generateAgentSlug(name: string, id: string): string {
  // Convert name to lowercase and replace spaces/special chars with dashes
  const nameSlug = name
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Remove accents
    .replace(/[^a-z0-9]+/g, '-') // Replace special chars with dash
    .replace(/^-+|-+$/g, ''); // Remove leading/trailing dashes
  
  return `${nameSlug}-${id}`;
}