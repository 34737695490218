import React from 'react';
import { motion } from 'framer-motion';
import { ShoppingBag, Shirt, Book as Book2, CreditCard, Coffee, ChevronRight, Pencil, Briefcase, Palette } from 'lucide-react';

const PRODUCTS = [
  {
    id: 'polo',
    name: 'Polo Palm',
    description: 'Polo professionnel avec logo brodé',
    icon: Shirt,
    options: ['Noir', 'Blanc'],
    color: '#30d158'
  },
  {
    id: 'notebook',
    name: 'Cahier + Stylo Palm',
    description: 'Set de papeterie professionnel',
    icon: Book2,
    color: '#0a84ff'
  },
  {
    id: 'card',
    name: 'Carte de visite Palm',
    description: 'Cartes de visite professionnelles',
    icon: CreditCard,
    color: '#bf5af2'
  },
  {
    id: 'mug',
    name: 'Mug Palm',
    description: 'Mug avec logo Palm',
    icon: Coffee,
    color: '#ff9f0a'
  }
];

export default function Merchandising() {
  const handleOrder = (product: typeof PRODUCTS[0], option?: string) => {
    const message = encodeURIComponent(
      `Bonjour,\n\n` +
      `Je souhaite commander :\n\n` +
      `Produit : ${product.name}\n` +
      (option ? `Option : ${option}\n` : '') +
      `\nMerci !`
    );
    window.open(`https://wa.me/971581998661?text=${message}`, '_blank');
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <div className="bg-[#1c1c1e]/90 backdrop-blur-xl sticky top-0 z-50 w-full border-b border-[#2c2c2e]">
        <div className="max-w-lg mx-auto px-4 py-3">
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
              <ShoppingBag className="w-6 h-6 text-[#0a84ff]" />
            </div>
            <div>
              <h1 className="text-[22px] font-semibold text-white">Merchandising</h1>
              <p className="text-[13px] text-[#98989f]">Commandez vos produits Palm</p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-lg mx-auto px-4 py-4 space-y-4">
        {/* Info Banner */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-[#26382f] rounded-xl p-4"
        >
          <div className="flex items-center gap-3">
            <div className="w-8 h-8 rounded-full bg-[#30d158]/10 flex items-center justify-center">
              <ShoppingBag className="w-5 h-5 text-[#30d158]" />
            </div>
            <p className="text-[15px] text-[#30d158]">
              Tous les produits sont gratuits pour les agents Palm
            </p>
          </div>
        </motion.div>

        {/* Products Grid */}
        <div className="grid grid-cols-1 gap-4">
          {PRODUCTS.map((product, index) => (
            <motion.div
              key={product.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-[#2c2c2e] rounded-xl overflow-hidden"
            >
              {/* Product Header */}
              <div className="p-6">
                <div className="flex items-center gap-4 mb-4">
                  <div className={`w-12 h-12 rounded-xl bg-[${product.color}]/10 flex items-center justify-center`}>
                    <product.icon className={`w-7 h-7 text-[${product.color}]`} />
                  </div>
                  <h3 className="text-[17px] font-medium text-white">{product.name}</h3>
                </div>
                <p className="text-[15px] text-[#98989f] mb-4">{product.description}</p>
              </div>

              {/* Product Actions */}
              <div className="p-4">
                {product.options ? (
                  <div className="space-y-2">
                    {product.options.map(option => (
                      <button
                        key={option}
                        onClick={() => handleOrder(product, option)}
                        className="w-full flex items-center justify-between px-4 py-3 bg-[#3a3a3c] rounded-xl hover:bg-[#48484a] transition-colors group"
                      >
                        <div className="flex items-center gap-3">
                          <product.icon className="w-5 h-5 text-[#0a84ff]" />
                          <span className="text-[15px] text-white">{option}</span>
                        </div>
                        <ChevronRight className="w-5 h-5 text-[#98989f] transition-transform group-hover:translate-x-1" />
                      </button>
                    ))}
                  </div>
                ) : (
                  <button
                    onClick={() => handleOrder(product)}
                    className="w-full flex items-center justify-between px-4 py-3 bg-[#3a3a3c] rounded-xl hover:bg-[#48484a] transition-colors group"
                  >
                    <div className="flex items-center gap-3">
                      <product.icon className="w-5 h-5 text-[#0a84ff]" />
                      <span className="text-[15px] text-white">Commander</span>
                    </div>
                    <ChevronRight className="w-5 h-5 text-[#98989f] transition-transform group-hover:translate-x-1" />
                  </button>
                )}
              </div>
            </motion.div>
          ))}
        </div>

        {/* Bottom Safe Area */}
        <div className="h-8" />
      </div>
    </div>
  );
}