import React from 'react';
import { motion } from 'framer-motion';
import { Check, X, AlertTriangle } from 'lucide-react';

export interface Toast {
  id: string;
  type: 'success' | 'error';
  message: string;
}

interface ToastProps {
  toast: Toast;
  onClose: (id: string) => void;
}

const Toast = ({ toast, onClose }: ToastProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20, scale: 0.95 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, y: -20, scale: 0.95 }}
      transition={{ type: "spring", stiffness: 300, damping: 25 }}
      className={`
        flex items-center gap-3 px-4 py-3 rounded-2xl shadow-lg
        ${toast.type === 'success'
          ? 'bg-[#26382f] border border-[#30d158]/20'
          : 'bg-[#3a2c29] border border-[#ff453a]/20'
        }
        backdrop-blur-xl
      `}
    >
      <div className={`
        w-8 h-8 rounded-xl flex items-center justify-center flex-shrink-0
        ${toast.type === 'success' ? 'bg-[#30d158]/10' : 'bg-[#ff453a]/10'}
      `}>
        {toast.type === 'success' ? (
          <Check className={`w-5 h-5 ${toast.type === 'success' ? 'text-[#30d158]' : 'text-[#ff453a]'}`} />
        ) : (
          <AlertTriangle className={`w-5 h-5 ${toast.type === 'success' ? 'text-[#30d158]' : 'text-[#ff453a]'}`} />
        )}
      </div>
      <p className={`text-[15px] font-medium ${toast.type === 'success' ? 'text-[#30d158]' : 'text-[#ff453a]'}`}>
        {toast.message}
      </p>
      <button
        onClick={() => onClose(toast.id)}
        className={`
          ml-auto p-2 rounded-xl transition-colors
          ${toast.type === 'success' 
            ? 'hover:bg-[#30d158]/10 text-[#30d158]' 
            : 'hover:bg-[#ff453a]/10 text-[#ff453a]'
          }
        `}
      >
        <X className="w-4 h-4" />
      </button>
    </motion.div>
  );
};

export function ToastContainer({ toasts, onClose }: { toasts: Toast[]; onClose: (id: string) => void }) {
  return (
    <div className="fixed top-4 left-1/2 -translate-x-1/2 z-50 flex flex-col gap-2">
      <motion.div
        initial={false}
        className="flex flex-col items-center gap-2"
      >
        {toasts.map(toast => (
          <Toast key={toast.id} toast={toast} onClose={onClose} />
        ))}
      </motion.div>
    </div>
  );
}

export default Toast;