import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Building, Star, Clock, ChevronRight, AlertTriangle, Users, Edit, X } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { useToast } from '../../contexts/ToastContext';
import { formatDateDisplay } from '../../utils/date';
import type { InvestorProperty, PropertyService } from '../../types';
import Layout from '../../components/Layout';

export default function InvestorServices() {
  const { showToast } = useToast();
  const [services, setServices] = useState<PropertyService[]>([]);
  const [properties, setProperties] = useState<Record<string, InvestorProperty>>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Get all service requests
        const { data: servicesData, error: servicesError } = await supabase
          .from('property_services')
          .select(`
            *,
            property:investor_properties(
              id,
              project,
              unit_number,
              status
            )
          `)
          .order('created_at', { ascending: false });

        if (servicesError) throw servicesError;
        if (!isMounted) return;

        // Get all properties referenced in services
        const propertyIds = [...new Set(servicesData?.map(s => s.property_id) || [])];
        const { data: propertiesData, error: propertiesError } = await supabase
          .from('investor_properties')
          .select('*')
          .in('id', propertyIds);

        if (propertiesError) throw propertiesError;
        if (!isMounted) return;

        // Create properties lookup object
        const propertiesLookup = propertiesData?.reduce((acc, property) => {
          acc[property.id] = property;
          return acc;
        }, {} as Record<string, InvestorProperty>);

        setServices(servicesData || []);
        setProperties(propertiesLookup || {});
      } catch (error) {
        console.error('Error fetching data:', error);
        if (isMounted) {
          setError('Erreur lors du chargement des données');
          showToast('error', 'Erreur lors du chargement des données');
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    // Realtime subscription for updates
    const servicesSubscription = supabase
      .channel('property_services_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'property_services'
        },
        () => {
          if (isMounted) {
            fetchData();
          }
        }
      )
      .subscribe();

    return () => {
      isMounted = false;
      servicesSubscription.unsubscribe();
    };
  }, [showToast]);

  const handleUpdateStatus = async (serviceId: string, newStatus: PropertyService['status']) => {
    try {
      const { error } = await supabase
        .from('property_services')
        .update({
          status: newStatus,
          ...(newStatus === 'completed' ? { completed_at: new Date().toISOString() } : {}),
          updated_at: new Date().toISOString()
        })
        .eq('id', serviceId);

      if (error) throw error;
      showToast('success', 'Statut mis à jour avec succès');
    } catch (error) {
      console.error('Error updating status:', error);
      showToast('error', 'Erreur lors de la mise à jour du statut');
    }
  };

  return (
    <Layout>
      <div className="min-h-screen bg-black">
        {/* Header */}
        <div className="bg-[#1c1c1e]/90 backdrop-blur-xl sticky top-0 z-50 w-full border-b border-[#2c2c2e]">
          <div className="max-w-lg mx-auto px-4 py-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                  <Star className="w-6 h-6 text-[#0a84ff]" />
                </div>
                <div>
                  <h1 className="text-[22px] font-semibold text-white">Services</h1>
                  <p className="text-[13px] text-[#98989f]">
                    {services.length} demandes • {services.filter(s => s.status === 'pending').length} en attente
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-lg mx-auto px-4 py-4">
          {loading ? (
            <div className="flex items-center justify-center min-h-[200px]">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#0a84ff]"></div>
            </div>
          ) : error ? (
            <div className="text-center py-12">
              <AlertTriangle className="w-12 h-12 text-[#ff453a] mx-auto mb-4" />
              <p className="text-[#98989f]">{error}</p>
            </div>
          ) : (
            <div className="space-y-4">
              {services.map(service => {
                const property = properties[service.property_id];
                if (!property) return null;

                return (
                  <div key={service.id} className="bg-[#2c2c2e] rounded-xl overflow-hidden">
                    <div className="p-4">
                      <div className="flex items-center justify-between mb-4">
                        <div>
                          <h3 className="text-[17px] font-medium text-white">{property.project}</h3>
                          <p className="text-[15px] text-[#98989f]">Unité {property.unitNumber}</p>
                        </div>
                        <div className={`px-3 py-1 rounded-xl text-[13px] font-medium ${
                          service.status === 'completed' ? 'bg-[#26382f] text-[#30d158]' :
                          service.status === 'pending' ? 'bg-[#3a2c29] text-[#ff453a]' :
                          service.status === 'in_progress' ? 'bg-[#1c3a4f] text-[#0a84ff]' :
                          'bg-[#2c2c2e] text-[#98989f]'
                        }`}>
                          {service.status === 'completed' ? 'Terminé' :
                           service.status === 'pending' ? 'En attente' :
                           service.status === 'in_progress' ? 'En cours' :
                           'Annulé'}
                        </div>
                      </div>

                      {service.description && (
                        <p className="text-[15px] text-[#98989f] mb-4">{service.description}</p>
                      )}

                      <div className="flex items-center justify-between pt-4 border-t border-[#3a3a3c]">
                        <div className="flex items-center gap-2">
                          <Clock className="w-4 h-4 text-[#98989f]" />
                          <span className="text-[13px] text-[#98989f]">
                            {service.completed_at ? 'Terminé le ' + formatDateDisplay(service.completed_at) : 'En cours'}
                          </span>
                        </div>
                        <button 
                          onClick={() => handleUpdateStatus(service.id, 'completed')}
                          className="p-2 text-[#0a84ff] hover:bg-[#0a84ff]/10 rounded-lg transition-colors"
                        >
                          <ChevronRight className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}

              {services.length === 0 && (
                <div className="text-center py-12">
                  <Star className="w-12 h-12 text-[#98989f] mx-auto mb-4" />
                  <p className="text-[#98989f]">Aucune demande de service trouvée</p>
                </div>
              )}
            </div>
          )}

          {/* Bottom Safe Area */}
          <div className="h-8" />
        </div>
      </div>
    </Layout>
  );
}