import React, { useState, useMemo } from 'react';
import { Users, Search, Download, Mail, Phone, Building, Calendar, LayoutGrid, Table, Star, ChevronRight, Copy } from 'lucide-react';
import { useSales } from '../hooks/useSales';
import { formatCurrency } from '../utils/format';
import { formatDateDisplay } from '../utils/date';
import { Link } from 'react-router-dom';
import BlurredNumber from '../components/ui/BlurredNumber';
import { useToast } from '../contexts/ToastContext';

export default function Clients() {
  const { sales, calculateCommission } = useSales();
  const { showToast } = useToast();
  const [searchQuery, setSearchQuery] = useState('');
  const [viewMode, setViewMode] = useState<'grid' | 'table'>('grid');

  // Group sales by client
  const clientData = useMemo(() => {
    const data = sales.reduce((acc, sale) => {
      const clientId = sale.client.email || sale.client.phone || sale.client.name;
      if (!clientId) return acc;

      if (!acc[clientId]) {
        acc[clientId] = {
          id: clientId,
          name: sale.client.name,
          email: sale.client.email,
          phone: sale.client.phone,
          sales: [],
          totalVolume: 0,
          totalCommission: 0,
          lastPurchase: sale.saleDate
        };
      }

      const commission = calculateCommission(sale);
      acc[clientId].sales.push(sale);
      acc[clientId].totalVolume += sale.propertyPrice;
      acc[clientId].totalCommission += commission.agency;
      
      // Update last purchase date if more recent
      if (new Date(sale.saleDate) > new Date(acc[clientId].lastPurchase)) {
        acc[clientId].lastPurchase = sale.saleDate;
      }

      return acc;
    }, {} as Record<string, {
      id: string;
      name: string;
      email?: string;
      phone?: string;
      sales: typeof sales;
      totalVolume: number;
      totalCommission: number;
      lastPurchase: string;
    }>);

    return Object.values(data);
  }, [sales]);

  // Filter clients based on search
  const filteredClients = clientData.filter(client => {
    if (!searchQuery) return true;
    
    const query = searchQuery.toLowerCase();
    return (
      client.name?.toLowerCase().includes(query) ||
      client.email?.toLowerCase().includes(query) ||
      client.phone?.toLowerCase().includes(query)
    );
  });

  // Generate CSV data
  const generateCSV = () => {
    const headers = ['Nom', 'Email', 'Téléphone', 'Nombre de ventes', 'Volume total', 'Commission totale', 'Dernier achat'];
    const rows = filteredClients.map(client => [
      client.name || 'N/A',
      client.email || 'N/A',
      client.phone || 'N/A',
      client.sales.length,
      formatCurrency(client.totalVolume),
      formatCurrency(client.totalCommission),
      formatDateDisplay(client.lastPurchase)
    ]);

    const csvContent = [
      headers.join(','),
      ...rows.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `clients_palm_${new Date().toISOString().split('T')[0]}.csv`;
    link.click();
  };

  const generatePalmPlusLink = (clientId: string) => {
    // Encode client ID for URL safety
    const encodedId = btoa(clientId).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return `${window.location.origin}/palmplus/${encodedId}`;
  };

  const copyPalmPlusLink = (clientId: string) => {
    const link = generatePalmPlusLink(clientId);
    navigator.clipboard.writeText(link);
    showToast('success', 'Lien Palm+ copié dans le presse-papier');
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <div className="bg-[#1c1c1e]/90 backdrop-blur-xl sticky top-0 z-50 w-full border-b border-[#2c2c2e]">
        <div className="max-w-7xl mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                <Users className="w-6 h-6 text-[#0a84ff]" />
              </div>
              <div>
                <h1 className="text-[22px] font-semibold text-white">Clients</h1>
                <p className="text-[13px] text-[#98989f]">
                  {clientData.length} clients • {sales.length} ventes
                </p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <div className="flex bg-[#2c2c2e] rounded-xl p-1">
                <button
                  onClick={() => setViewMode('grid')}
                  className={`p-2 rounded-lg transition-colors ${
                    viewMode === 'grid'
                      ? 'bg-[#0a84ff] text-white'
                      : 'text-[#98989f] hover:text-white'
                  }`}
                >
                  <LayoutGrid className="w-5 h-5" />
                </button>
                <button
                  onClick={() => setViewMode('table')}
                  className={`p-2 rounded-lg transition-colors ${
                    viewMode === 'table'
                      ? 'bg-[#0a84ff] text-white'
                      : 'text-[#98989f] hover:text-white'
                  }`}
                >
                  <Table className="w-5 h-5" />
                </button>
              </div>
              <button
                onClick={generateCSV}
                className="px-4 py-2 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors flex items-center gap-2"
              >
                <Download className="w-5 h-5" />
                Exporter CSV
              </button>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 py-3 border-t border-[#2c2c2e]">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
            <input
              type="text"
              placeholder="Rechercher un client..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 bg-[#2c2c2e] text-white placeholder-[#98989f] rounded-lg focus:ring-2 focus:ring-[#0a84ff] border-none"
            />
          </div>
        </div>
      </div>

      {/* Clients List */}
      {viewMode === 'grid' ? (
        <div className="max-w-7xl mx-auto px-4 py-4 space-y-4">
          {filteredClients.map((client) => (
            <div key={client.id} className="bg-[#2c2c2e] rounded-xl overflow-hidden">
              <div className="p-6">
                <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-6">
                  {/* Client Info */}
                  <div className="flex-1">
                    <Link 
                      to={`/clients/${client.email || client.phone}`}
                      className="text-[22px] font-semibold text-white hover:text-[#0a84ff] transition-colors"
                    >
                      {client.name || 'Client sans nom'}
                    </Link>
                    <div className="mt-2 space-y-1">
                      {client.email && (
                        <a 
                          href={`mailto:${client.email}`}
                          className="flex items-center gap-2 text-[15px] text-[#98989f] hover:text-[#0a84ff] transition-colors"
                        >
                          <Mail className="w-4 h-4" />
                          {client.email}
                        </a>
                      )}
                      {client.phone && (
                        <a 
                          href={`tel:${client.phone}`}
                          className="flex items-center gap-2 text-[15px] text-[#98989f] hover:text-[#0a84ff] transition-colors"
                        >
                          <Phone className="w-4 h-4" />
                          {client.phone}
                        </a>
                      )}
                    </div>
                  </div>

                  {/* Stats */}
                  <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                    <div className="p-4 bg-[#3a3a3c] rounded-xl">
                      <div className="flex items-center gap-2 mb-2">
                        <Building className="w-4 h-4 text-[#0a84ff]" />
                        <p className="text-[13px] text-[#98989f]">Ventes</p>
                      </div>
                      <p className="text-[17px] font-bold text-white">{client.sales.length}</p>
                    </div>

                    <div className="p-4 bg-[#3a3a3c] rounded-xl">
                      <div className="flex items-center gap-2 mb-2">
                        <Building className="w-4 h-4 text-[#30d158]" />
                        <p className="text-[13px] text-[#98989f]">Volume total</p>
                      </div>
                      <p className="text-[17px] font-bold text-[#30d158]">
                        <BlurredNumber value={formatCurrency(client.totalVolume)} />
                      </p>
                    </div>

                    <div className="p-4 bg-[#3a3a3c] rounded-xl">
                      <div className="flex items-center gap-2 mb-2">
                        <Building className="w-4 h-4 text-[#bf5af2]" />
                        <p className="text-[13px] text-[#98989f]">Commission</p>
                      </div>
                      <p className="text-[17px] font-bold text-[#bf5af2]">
                        <BlurredNumber value={formatCurrency(client.totalCommission)} />
                      </p>
                    </div>

                    <div className="p-4 bg-[#3a3a3c] rounded-xl">
                      <div className="flex items-center gap-2 mb-2">
                        <Calendar className="w-4 h-4 text-[#ff9f0a]" />
                        <p className="text-[13px] text-[#98989f]">Dernier achat</p>
                      </div>
                      <p className="text-[15px] text-[#ff9f0a]">
                        {formatDateDisplay(client.lastPurchase)}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Palm+ Link */}
                <div className="mt-6 p-4 bg-[#1c3a4f] rounded-xl border border-[#0a84ff]/20">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      <Star className="w-5 h-5 text-[#0a84ff]" />
                      <div>
                        <h4 className="text-[15px] font-medium text-white">Lien Palm+</h4>
                        <p className="text-[13px] text-[#98989f]">Accès client au CRM</p>
                      </div>
                    </div>
                    <button
                      onClick={() => copyPalmPlusLink(client.id)}
                      className="px-3 py-1.5 bg-[#0a84ff] text-white rounded-xl text-[13px] font-medium flex items-center gap-2"
                    >
                      <Copy className="w-4 h-4" />
                      Copier le lien
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="max-w-7xl mx-auto px-4 py-4">
          <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-[#3a3a3c]">
                    <th className="text-left p-4 text-[#98989f] font-medium">Client</th>
                    <th className="text-left p-4 text-[#98989f] font-medium">Ventes</th>
                    <th className="text-left p-4 text-[#98989f] font-medium">Volume total</th>
                    <th className="text-left p-4 text-[#98989f] font-medium">Commission</th>
                    <th className="text-left p-4 text-[#98989f] font-medium">Dernier achat</th>
                    <th className="text-left p-4 text-[#98989f] font-medium">Palm+</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredClients.map((client) => (
                    <tr key={client.id} className="border-b border-[#3a3a3c] hover:bg-[#3a3a3c] transition-colors">
                      <td className="p-4">
                        <Link 
                          to={`/clients/${client.email || client.phone}`}
                          className="block group"
                        >
                          <p className="text-white font-medium group-hover:text-[#0a84ff] transition-colors">
                            {client.name || 'Client sans nom'}
                          </p>
                          {client.email && (
                            <p className="text-[#98989f] text-sm">{client.email}</p>
                          )}
                          {client.phone && (
                            <p className="text-[#98989f] text-sm">{client.phone}</p>
                          )}
                        </Link>
                      </td>
                      <td className="p-4">
                        <p className="text-white">{client.sales.length}</p>
                      </td>
                      <td className="p-4">
                        <p className="text-white">
                          <BlurredNumber value={formatCurrency(client.totalVolume)} />
                        </p>
                      </td>
                      <td className="p-4">
                        <p className="text-white">
                          <BlurredNumber value={formatCurrency(client.totalCommission)} />
                        </p>
                      </td>
                      <td className="p-4">
                        <div className="flex items-center gap-2 text-[#98989f]">
                          <Calendar className="w-4 h-4" />
                          <span>{formatDateDisplay(client.lastPurchase)}</span>
                        </div>
                      </td>
                      <td className="p-4">
                        <button
                          onClick={() => copyPalmPlusLink(client.id)}
                          className="px-3 py-1.5 bg-[#0a84ff] text-white rounded-xl text-[13px] font-medium flex items-center gap-2"
                        >
                          <Copy className="w-4 h-4" />
                          Copier le lien
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {filteredClients.length === 0 && (
        <div className="text-center py-12">
          <Users className="w-12 h-12 text-[#98989f] mx-auto mb-4" />
          <p className="text-[#98989f]">Aucun client trouvé</p>
        </div>
      )}

      {/* Bottom Safe Area */}
      <div className="h-8" />
    </div>
  );
}