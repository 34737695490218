import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Building, Calendar, Wifi, Wind, Thermometer, Users, MessageSquare, ChevronRight, ChevronLeft, ChevronDown, Bell } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import { notifyOfficeSpaceOpening } from '../../services/notifications/officeSpaceNotification';
import { useToast } from '../../contexts/ToastContext';

interface Image {
  url: string;
  description: string;
}

const OFFICE_IMAGES: Image[] = [
  {
    url: "https://www.palmdubairealestate.com/uploads/img_6462.jpg",
    description: "Office Space"
  },
  {
    url: "https://www.palmdubairealestate.com/uploads/whatsapp-image-2025-02-24-at-16_00_18.jpg",
    description: "Office Space"
  }
];

export default function AgentOfficeSpace() {
  const [carRegistration, setCarRegistration] = useState('');
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [showFullscreen, setShowFullscreen] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);

  // Initialize date with today's date
  const today = new Date();
  const [date, setDate] = useState(today.toISOString().split('T')[0]);

  const getFormattedDate = (daysToAdd: number = 0) => {
    const date = new Date();
    date.setDate(date.getDate() + daysToAdd);
    return date.toISOString().split('T')[0];
  };

  const getDateLabel = (dateStr: string) => {
    const date = new Date(dateStr);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const afterTomorrow = new Date(today);
    afterTomorrow.setDate(afterTomorrow.getDate() + 2);

    if (date.toDateString() === today.toDateString()) return 'Aujourd\'hui';
    if (date.toDateString() === tomorrow.toDateString()) return 'Demain';
    if (date.toDateString() === afterTomorrow.toDateString()) return 'Après-demain';
    
    return date.toLocaleDateString('fr-FR', {
      weekday: 'long',
      day: 'numeric',
      month: 'long'
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Format message for WhatsApp
    const message = encodeURIComponent(
      `🏢 Réservation Office Space\n\n` +
      `📅 Date: ${date}\n` +
      `🚗 Immatriculation: ${carRegistration}\n\n` +
      `Merci de confirmer ma réservation.`
    );
    
    // Open WhatsApp with pre-filled message
    window.open(`https://wa.me/971581998661?text=${message}`, '_blank');
  };

  const handleNotifyAll = async () => {
    setIsNotifying(true);
    try {
      await notifyOfficeSpaceOpening();
      showToast('success', 'Notifications envoyées avec succès');
    } catch (error) {
      showToast('error', 'Erreur lors de l\'envoi des notifications');
    } finally {
      setIsNotifying(false);
    }
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <div className="bg-[#1c1c1e]/90 backdrop-blur-xl sticky top-0 z-50 w-full border-b border-[#2c2c2e] transition-all duration-300">
        <div className="max-w-lg mx-auto px-4 py-3">
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
              <Building className="w-6 h-6 text-[#0a84ff]" />
            </div>
            <div>
              <h1 className="text-[22px] font-semibold text-white">Office Space</h1>
              <p className="text-[13px] text-[#98989f]">Réservez votre espace de travail</p>
            </div>
          </div>
        </div>
      </div>

      {/* Fullscreen Image Modal */}
      {showFullscreen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black z-50 flex flex-col"
        >
          <div className="p-4 flex items-center justify-between bg-[#1c1c1e]/80 backdrop-blur-xl">
            <button
              onClick={() => setShowFullscreen(false)}
              className="p-2 text-white hover:bg-white/10 rounded-full transition-colors"
            >
              <ChevronDown className="w-6 h-6" />
            </button>
            <p className="text-white font-medium">
              {selectedImage + 1} / {OFFICE_IMAGES.length}
            </p>
            <div className="w-10" /> {/* Spacer for alignment */}
          </div>

          <div className="flex-1 relative">
            <div className="absolute inset-0 flex items-center">
              <button
                onClick={() => setSelectedImage(prev => 
                  prev === 0 ? OFFICE_IMAGES.length - 1 : prev - 1
                )}
                className="p-2 bg-[#1c1c1e]/80 backdrop-blur-xl rounded-r-xl text-white"
              >
                <ChevronLeft className="w-6 h-6" />
              </button>
              
              <div className="flex-1">
                <img
                  src={OFFICE_IMAGES[selectedImage].url}
                  alt={OFFICE_IMAGES[selectedImage].description}
                  className="w-full h-full object-contain"
                />
              </div>
              
              <button
                onClick={() => setSelectedImage(prev => 
                  prev === OFFICE_IMAGES.length - 1 ? 0 : prev + 1
                )}
                className="p-2 bg-[#1c1c1e]/80 backdrop-blur-xl rounded-l-xl text-white"
              >
                <ChevronRight className="w-6 h-6" />
              </button>
            </div>
          </div>

          <div className="p-4 bg-[#1c1c1e]/80 backdrop-blur-xl">
            <p className="text-white text-center">
              {OFFICE_IMAGES[selectedImage].description}
            </p>
          </div>
        </motion.div>
      )}

      {/* Image Gallery */}
      <div className="relative max-w-lg mx-auto px-4 pt-6 pb-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="relative aspect-video rounded-2xl overflow-hidden mb-6 border border-[#3a3a3c] shadow-lg"
          onClick={() => setShowFullscreen(true)}
        >
          <img
            src={OFFICE_IMAGES[selectedImage].url}
            alt={OFFICE_IMAGES[selectedImage].description}
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black/60 to-transparent p-4">
            <p className="text-white text-sm font-medium">
              {OFFICE_IMAGES[selectedImage].description}
            </p>
          </div>
        </motion.div>

        <div className="flex gap-2 overflow-x-auto pb-4 scrollbar-hide -mx-4 px-4 pt-2">
          {OFFICE_IMAGES.map((image, index) => (
            <motion.button
              key={index}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: index * 0.1 }}
              onClick={() => setSelectedImage(index)}
              className={`relative flex-shrink-0 rounded-xl overflow-hidden aspect-video w-24 border border-[#3a3a3c] shadow-sm ${
                selectedImage === index ? 'ring-2 ring-[#0a84ff]' : ''
              }`}
            >
              <img
                src={image.url}
                alt={image.description}
                className="w-full h-full object-cover"
              />
            </motion.button>
          ))}
        </div>
      </div>
      <div className="max-w-lg mx-auto px-4 py-4 space-y-6">
        {/* Office Info Card */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-[#2c2c2e] rounded-xl overflow-hidden"
        >
          <div className="p-6">
            <div className="flex items-center gap-3 mb-6">
              <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                <Building className="w-6 h-6 text-[#0a84ff]" />
              </div>
              <h2 className="text-[17px] font-medium text-white">Espace de Travail</h2>
            </div>

            <p className="text-[15px] text-[#98989f] leading-relaxed mb-6">
              Un espace de 12m² à votre disposition pour vos appels clients, réunions d'équipe ou simplement pour travailler au calme dans un environnement isolé et professionnel.
            </p>

            {/* Rules Section */}
            <div className="p-4 bg-[#3a2c29] rounded-xl mb-6">
              <h3 className="text-[15px] font-medium text-[#ff453a] mb-3">Règles importantes</h3>
              <ul className="space-y-2 text-[13px] text-[#98989f]">
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 rounded-full bg-[#ff453a]" />
                  <span>Accessible 24h/24 7j/7</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 rounded-full bg-[#ff453a]" />
                  <span>Ne pas laisser de déchets (merci de jeter vos déchets dans la poubelle sur la rue)</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 rounded-full bg-[#ff453a]" />
                  <span>Ne pas fumer à l'intérieur</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 rounded-full bg-[#ff453a]" />
                  <span>Couper la climatisation à votre sortie</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 rounded-full bg-[#ff453a]" />
                  <span>Couper l'éclairage à votre sortie</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 rounded-full bg-[#ff453a]" />
                  <span>Maintenir le portail du jardin fermé</span>
                </li>
              </ul>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <div className="flex items-center gap-2 mb-2">
                  <Wifi className="w-5 h-5 text-[#0a84ff]" />
                  <span className="text-[15px] text-white">WiFi</span>
                </div>
                <p className="text-[13px] text-[#98989f]">
                  Réseau : 259<br />
                  Mot de passe : 170990Abc@
                </p>
              </div>

              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <div className="flex items-center gap-2 mb-2">
                  <Wind className="w-5 h-5 text-[#30d158]" />
                  <span className="text-[15px] text-white">Boissons fraîches</span>
                </div>
                <p className="text-[13px] text-[#98989f]">Disponibles sur place</p>
              </div>

              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <div className="flex items-center gap-2 mb-2">
                  <Thermometer className="w-5 h-5 text-[#ff453a]" />
                  <span className="text-[15px] text-white">Climatisation</span>
                </div>
                <p className="text-[13px] text-[#98989f]">Température idéale</p>
              </div>

              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <div className="flex items-center gap-2 mb-2">
                  <Users className="w-5 h-5 text-[#bf5af2]" />
                  <span className="text-[15px] text-white">Capacité</span>
                </div>
                <p className="text-[13px] text-[#98989f]">4 à 6 personnes</p>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Booking Form */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-[#2c2c2e] rounded-xl overflow-hidden"
        >
          <div className="p-6">
            <div className="flex items-center gap-3 mb-6">
              <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                <Calendar className="w-6 h-6 text-[#0a84ff]" />
              </div>
              <h2 className="text-[17px] font-medium text-white">Réserver</h2>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-[13px] text-[#98989f] mb-2">
                  Date souhaitée
                </label>
                <div className="relative">
                  <button
                    type="button"
                    onClick={() => setShowDatePicker(!showDatePicker)}
                    className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] text-left flex items-center justify-between"
                  >
                    <span>{getDateLabel(date)}</span>
                    <Calendar className="w-5 h-5 text-[#98989f]" />
                  </button>
                  
                  {showDatePicker && (
                    <div className="fixed inset-x-0 mx-4 mt-2 p-2 bg-[#2c2c2e] rounded-xl border border-[#3a3a3c] shadow-lg z-[9999]">
                      <div className="space-y-2">
                        <button
                          type="button"
                          onClick={() => {
                            setDate(getFormattedDate(0));
                            setShowDatePicker(false);
                          }}
                          className="w-full px-4 py-2 text-left hover:bg-[#3a3a3c] rounded-lg transition-colors text-white"
                        >
                          Aujourd'hui
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setDate(getFormattedDate(1));
                            setShowDatePicker(false);
                          }}
                          className="w-full px-4 py-2 text-left hover:bg-[#3a3a3c] rounded-lg transition-colors text-white"
                        >
                          Demain
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setDate(getFormattedDate(2));
                            setShowDatePicker(false);
                          }}
                          className="w-full px-4 py-2 text-left hover:bg-[#3a3a3c] rounded-lg transition-colors text-white"
                        >
                          Après-demain
                        </button>
                        <div className="relative py-2">
                          <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-[#3a3a3c]"></div>
                          </div>
                          <div className="relative flex justify-center">
                            <span className="px-2 bg-[#2c2c2e] text-[#98989f] text-sm">ou</span>
                          </div>
                        </div>
                        <input
                          type="date"
                          value={date}
                          onChange={(e) => {
                            setDate(e.target.value);
                            setShowDatePicker(false);
                          }}
                          className="w-full px-4 py-2 bg-[#3a3a3c] text-white border-none rounded-lg focus:ring-2 focus:ring-[#0a84ff]"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div>
                <label className="block text-[13px] text-[#98989f] mb-2">
                  Immatriculation du véhicule
                </label>
                <input
                  type="text"
                  required
                  value={carRegistration}
                  onChange={(e) => setCarRegistration(e.target.value)}
                  className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                  placeholder="Ex: A 12345"
                />
              </div>

              <button
                type="submit"
                className="w-full mt-6 flex items-center justify-between px-4 py-3 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors"
              >
                <div className="flex items-center gap-3">
                  <MessageSquare className="w-5 h-5" />
                  <span className="text-[15px] font-medium">Réserver sur WhatsApp</span>
                </div>
                <ChevronRight className="w-5 h-5" />
              </button>
            </form>
          </div>
        </motion.div>

        {/* Ideal Use Cases */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-[#2c2c2e] rounded-xl overflow-hidden"
        >
          <div className="p-6">
            <div className="flex items-center gap-3 mb-6">
              <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                <Users className="w-6 h-6 text-[#0a84ff]" />
              </div>
              <h2 className="text-[17px] font-medium text-white">Idéal pour</h2>
            </div>

            <div className="space-y-4">
              {/* Google Meet */}
              <div className="p-4 bg-[#3a3a3c] rounded-xl relative overflow-hidden">
                <div className="absolute inset-0 bg-[url('https://www.gstatic.com/meet/google_meet_marketing_ongoing_meeting_grid_427cbb32d746b1d0133b898b50115e96.jpg')] opacity-5 bg-cover bg-center" />
                <div className="relative">
                  <div className="flex items-center gap-2 mb-2">
                    <svg viewBox="0 0 87.5 72" className="w-5 h-5">
                      <path d="m82.5 11.4v49.2c0 6.2-5 11.4-11.4 11.4h-54.7c-6.2 0-11.4-5-11.4-11.4v-49.2c0-6.2 5-11.4 11.4-11.4h54.9c6.2 0 11.2 5.2 11.2 11.4zm-38.3 44.1 21.6-21.8-21.6-21.6v14.8h-27.3v13.6h27.3z" fill="#00AC47"/>
                    </svg>
                    <span className="text-[15px] text-white">Google Meet</span>
                  </div>
                  <p className="text-[13px] text-[#98989f]">
                    Environnement professionnel et calme pour vos visioconférences avec les clients
                  </p>
                </div>
              </div>

              {/* Team Leaders */}
              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <div className="flex items-center gap-2 mb-2">
                  <Users className="w-5 h-5 text-[#30d158]" />
                  <span className="text-[15px] text-white">Formation d'équipe</span>
                </div>
                <p className="text-[13px] text-[#98989f]">
                  Espace parfait pour les Team Leaders qui souhaitent former et encadrer leurs agents
                </p>
              </div>

              {/* Focus Time */}
              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <div className="flex items-center gap-2 mb-2">
                  <Wind className="w-5 h-5 text-[#bf5af2]" />
                  <span className="text-[15px] text-white">Concentration maximale</span>
                </div>
                <p className="text-[13px] text-[#98989f]">
                  Environnement calme et isolé idéal pour les sessions d'appels intensives sans interruption
                </p>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Bottom Safe Area */}
        <div className="h-8" />
      </div>

    </div>
  );
}