import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Building, DollarSign, Home, TrendingUp, Calendar, Gift, ChevronRight } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import { supabase } from '../../lib/supabase';
import { formatCurrency, formatEuro } from '../../utils/format';
import { formatDateDisplay } from '../../utils/date';
import { useParams, Navigate } from 'react-router-dom';
import type { InvestorPortfolio, InvestorProperty, ConstructionUpdate } from '../../types';
import BlurredNumber from '../../components/ui/BlurredNumber';

export default function InvestorDashboard() {
  const { user } = useAuth();
  const { clientId } = useParams<{ clientId?: string }>();
  const [portfolio, setPortfolio] = useState<InvestorPortfolio | null>(null);
  const [properties, setProperties] = useState<InvestorProperty[]>([]);
  const [latestUpdates, setLatestUpdates] = useState<ConstructionUpdate[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let userId = user?.id;

        // If clientId is provided, decode it
        if (clientId) {
          try {
            // Decode base64 URL-safe string
            const decodedId = atob(clientId.replace(/-/g, '+').replace(/_/g, '/'));
            userId = decodedId;
          } catch (error) {
            console.error('Error decoding client ID:', error);
            return;
          }
        }

        if (!userId) return;

        // Get investor portfolio
        const { data: portfolioData } = await supabase
          .from('investor_portfolios')
          .select('*')
          .eq('user_id', userId)
          .single();

        if (portfolioData) {
          setPortfolio(portfolioData);

          // Get properties
          const { data: propertiesData } = await supabase
            .from('investor_properties')
            .select('*')
            .eq('portfolio_id', portfolioData.id)
            .order('created_at', { ascending: false });

          setProperties(propertiesData || []);

          // Get latest updates
          const { data: updatesData } = await supabase
            .from('construction_updates')
            .select('*')
            .in('property_id', propertiesData?.map(p => p.id) || [])
            .order('created_at', { ascending: false })
            .limit(5);

          setLatestUpdates(updatesData || []);
        }
      } catch (error) {
        console.error('Error fetching investor data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, clientId]);

  // Redirect if no user or invalid client ID
  if (!loading && !portfolio) {
    return <Navigate to="/login" />;
  }

  if (loading) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#0a84ff]"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <div className="bg-[#1c1c1e]/90 backdrop-blur-xl sticky top-0 z-50 w-full border-b border-[#2c2c2e]">
        <div className="max-w-lg mx-auto px-4 py-3">
          <div className="flex items-center gap-3">
            <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
              <Building className="w-6 h-6 text-[#0a84ff]" />
            </div>
            <div>
              <h1 className="text-[22px] font-semibold text-white">Palm+</h1>
              <p className="text-[13px] text-[#98989f]">
                {properties.length} biens • {formatCurrency(portfolio?.totalInvestment || 0)}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-lg mx-auto px-4 py-4 space-y-6">
        {/* Investment Stats */}
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-[#2c2c2e] rounded-xl p-4">
            <div className="flex items-center gap-2 mb-2">
              <DollarSign className="w-4 h-4 text-[#30d158]" />
              <p className="text-[13px] text-[#98989f]">Investissement total</p>
            </div>
            <p className="text-[22px] font-bold text-[#30d158]">
              <BlurredNumber value={formatCurrency(portfolio?.totalInvestment || 0)} />
            </p>
            <p className="text-[13px] text-[#98989f]">
              <BlurredNumber value={formatEuro(portfolio?.totalInvestment || 0)} />
            </p>
          </div>

          <div className="bg-[#2c2c2e] rounded-xl p-4">
            <div className="flex items-center gap-2 mb-2">
              <Home className="w-4 h-4 text-[#0a84ff]" />
              <p className="text-[13px] text-[#98989f]">Biens</p>
            </div>
            <p className="text-[22px] font-bold text-white">
              {properties.length}
            </p>
            <p className="text-[13px] text-[#98989f]">
              {properties.filter(p => p.status === 'under_construction').length} en construction
            </p>
          </div>
        </div>

        {/* Properties List */}
        <div className="space-y-4">
          <h2 className="text-[17px] font-medium text-white">Mes biens</h2>
          
          {properties.map(property => (
            <div key={property.id} className="bg-[#2c2c2e] rounded-xl overflow-hidden">
              <div className="p-4">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <h3 className="text-[17px] font-medium text-white">{property.project}</h3>
                    <p className="text-[15px] text-[#98989f]">Unité {property.unitNumber}</p>
                  </div>
                  <div className={`px-3 py-1 rounded-xl text-[13px] font-medium ${
                    property.status === 'completed' ? 'bg-[#26382f] text-[#30d158]' :
                    property.status === 'under_construction' ? 'bg-[#3a2c29] text-[#ff453a]' :
                    'bg-[#2c2c2e] text-[#98989f]'
                  }`}>
                    {property.status === 'completed' ? 'Livré' :
                     property.status === 'under_construction' ? 'En construction' :
                     property.status === 'rented' ? 'Loué' :
                     property.status === 'for_sale' ? 'En vente' :
                     'Vendu'}
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div>
                    <p className="text-[13px] text-[#98989f] mb-1">Prix d'achat</p>
                    <p className="text-[17px] font-bold text-white">
                      <BlurredNumber value={formatCurrency(property.purchasePrice)} />
                    </p>
                    <p className="text-[13px] text-[#98989f]">
                      <BlurredNumber value={formatEuro(property.purchasePrice)} />
                    </p>
                  </div>

                  <div>
                    <p className="text-[13px] text-[#98989f] mb-1">Date d'achat</p>
                    <p className="text-[15px] text-white">
                      {formatDateDisplay(property.purchaseDate)}
                    </p>
                  </div>
                </div>

                <div className="flex items-center justify-between pt-4 border-t border-[#3a3a3c]">
                  <div className="flex items-center gap-2">
                    <Calendar className="w-4 h-4 text-[#98989f]" />
                    <span className="text-[13px] text-[#98989f]">
                      Livraison : {property.handoverDate ? formatDateDisplay(property.handoverDate) : 'Non définie'}
                    </span>
                  </div>
                  <button className="p-2 text-[#0a84ff] hover:bg-[#0a84ff]/10 rounded-lg transition-colors">
                    <ChevronRight className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Latest Updates */}
        {latestUpdates.length > 0 && (
          <div className="space-y-4">
            <h2 className="text-[17px] font-medium text-white">Dernières mises à jour</h2>
            
            {latestUpdates.map(update => {
              const property = properties.find(p => p.id === update.propertyId);
              
              return (
                <div key={update.id} className="bg-[#2c2c2e] rounded-xl p-4">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <h3 className="text-[15px] font-medium text-white">{property?.project}</h3>
                      <p className="text-[13px] text-[#98989f]">Unité {property?.unitNumber}</p>
                    </div>
                    <div className="px-3 py-1 bg-[#3a3a3c] rounded-xl">
                      <span className="text-[13px] text-[#98989f]">
                        {update.completionPercentage}% complété
                      </span>
                    </div>
                  </div>

                  <p className="text-[15px] text-white mb-4">{update.description}</p>

                  {update.images.length > 0 && (
                    <div className="grid grid-cols-2 gap-2">
                      {update.images.slice(0, 4).map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={`Update ${index + 1}`}
                          className="w-full h-32 object-cover rounded-lg"
                        />
                      ))}
                    </div>
                  )}

                  <div className="flex items-center gap-2 mt-4 text-[13px] text-[#98989f]">
                    <Calendar className="w-4 h-4" />
                    <span>{formatDateDisplay(update.createdAt)}</span>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* Bottom Safe Area */}
        <div className="h-8" />
      </div>
    </div>
  );
}