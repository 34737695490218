import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAgentSlug } from '../../hooks/useAgentSlug';
import { useSales } from '../../hooks/useSales';
import { useAgents } from '../../hooks/useAgents';
import { useSettings } from '../../hooks/useSettings';
import ReferralBanner from '../../components/agent-dashboard/ReferralBanner';
import { motion } from 'framer-motion';
import { Building, DollarSign, Gift, Trophy, Users, Calendar, Clock, Target, ChevronRight, Crown, Star, Calculator, Globe, TrendingUp, Instagram } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { formatCurrency, formatEuro } from '../../utils/format';
import { useEffect } from 'react';
import { fr } from 'date-fns/locale';
import MonthlyCommissionHistory from './components/MonthlyCommissionHistory';
import ReferralSalesSection from './components/ReferralSalesSection';
import QuarterlyGoalCard from '../../components/agent-dashboard/QuarterlyGoalCard';
import QuarterlyRanking from './components/QuarterlyRanking';
import MonthlyGoalCard from '../../components/agent-dashboard/MonthlyGoalCard';
import LastSaleCard from './components/LastSaleCard';
import UnderperformingAlert from './components/UnderperformingAlert';
import InactivityAlert from './components/InactivityAlert';
import PerformanceSection from './components/PerformanceSection';
import CommissionStats from './components/CommissionStats';
import StatsOverview from './components/StatsOverview';
import QuickActions from './components/QuickActions';
import VirtualBusinessCard from '../../components/agent-dashboard/VirtualBusinessCard';
import { sendWhatsAppMessage } from '../../services/notificationService';
import ExclusiveRewards from '../../components/agent-dashboard/ExclusiveRewards';

export default function AgentDashboard() {
  const { agent, slug } = useAgentSlug();
  const { sales, calculateCommission } = useSales();
  const { agents } = useAgents();
  const { settings } = useSettings();
  const [showEditPopup, setShowEditPopup] = useState(false);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const quarterlyGoal = settings?.quarterlyGoals?.[agent?.id];
  const conventionSigned = quarterlyGoal?.conventionSigned || false;

  if (!agent) return null;

  // Get agent's sales first
  const agentSales = sales.filter(sale => 
    sale.agentId === agent.id || 
    sale.referrerId === agent.id ||
    sale.manualCommissions?.additionalAgents?.some(a => a.agentId === agent.id)
  ).sort((a, b) => new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime());

  // Get current month's sales
  const currentMonthSales = agentSales.filter(sale => {
    const saleDate = new Date(sale.saleDate);
    const isCurrentMonth = saleDate.getMonth() === currentMonth && saleDate.getFullYear() === currentYear;
    const isInvolved = sale.agentId === agent.id || 
                      sale.referrerId === agent.id || 
                      sale.manualCommissions?.additionalAgents?.some(a => a.agentId === agent.id);
    return isCurrentMonth && isInvolved;
  });

  // Calculate current month's commission
  const currentMonthCommission = currentMonthSales.reduce((total, sale) => {
    const commission = calculateCommission(sale);
    let saleCommission = 0;
    
    if (sale.agentId === agent.id) {
      saleCommission = commission.agent;
      if (sale.bonus) {
        saleCommission += commission.bonus.agent;
      }
    } else if (sale.referrerId === agent.id) {
      saleCommission = commission.referrer || 0;
      if (sale.bonus?.secondAgentId === agent.id) {
        saleCommission += commission.bonus.referrer;
      }
    } else if (sale.manualCommissions?.additionalAgents) {
      const additionalAgent = sale.manualCommissions.additionalAgents.find(a => a.agentId === agent.id);
      if (additionalAgent) {
        saleCommission = additionalAgent.amount;
        if (sale.bonus?.agents) {
          const bonusAmount = sale.bonus.agents.find(a => a.agentId === agent.id)?.amount || 0;
          saleCommission += bonusAmount;
        }
      }
    }
    
    return total + saleCommission;
  }, 0);

  // Calculate total sales for status
  const totalSales = agentSales.length;
  const status = totalSales >= 50 ? 'Elite' : totalSales >= 25 ? 'Premium' : 'Standard';
  const nextStatus = status === 'Elite' ? null : status === 'Premium' ? 'Elite' : 'Premium';
  const nextStatusThreshold = status === 'Standard' ? 25 : 50;
  const progressToNextStatus = (totalSales / nextStatusThreshold) * 100;

  // Get the most recent and first sale
  const lastSale = agentSales[0];
  const firstSale = agentSales[agentSales.length - 1];

  // Calculate days since last sale
  const daysSinceLastSale = lastSale 
    ? formatDistanceToNow(new Date(lastSale.saleDate), { locale: fr, addSuffix: true })
    : 'Aucune vente';

  // Calculate monthly average
  const calculateMonthlyAverage = () => {
    if (!firstSale || !lastSale) return 0;
    const firstDate = new Date(firstSale.saleDate);
    const lastDate = new Date(lastSale.saleDate);
    // Calculate exact months difference including partial months
    const monthsDiff = Math.max(1, (lastDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24 * 30.44));

    return agentSales.length / Math.max(1, monthsDiff);
  };

  const monthlyAverage = calculateMonthlyAverage();

  const commission = lastSale ? calculateCommission(lastSale) : null;
  
  // Calculate average time between EOI and commission payment
  const paymentTimeStats = agentSales.reduce((acc, sale) => {
    if (sale.client.payments?.eoi?.date && sale.paymentStatus.developer) {
      const eoiDate = new Date(sale.client.payments.eoi.date);
      // Find the commission payment date from the sale history
      const commissionDate = new Date(sale.commissionReceivedDate || sale.updatedAt);
      const daysDiff = Math.round((commissionDate.getTime() - eoiDate.getTime()) / (1000 * 60 * 60 * 24));
      acc.totalDays += daysDiff;
      acc.count += 1;
    }
    return acc;
  }, { totalDays: 0, count: 0 });

  const averagePaymentDays = paymentTimeStats.count > 0 
    ? Math.round(paymentTimeStats.totalDays / paymentTimeStats.count)
    : null;

  // Round monthly average to nearest whole number
  const roundedMonthlyAverage = Math.round(monthlyAverage);
  
  // Calculate monthly average sales
  const monthlyAverageSales = agentSales.length === 0 ? 0 : 
    agentSales.length / Math.max(1, (new Date().getTime() - new Date(agentSales[agentSales.length - 1].saleDate).getTime()) / (1000 * 60 * 60 * 24 * 30.44));

  // Calculate monthly data
  const monthlyData = agentSales.reduce((acc, sale) => {
    const date = new Date(sale.saleDate);
    const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
    const commission = calculateCommission(sale);
    
    if (!acc[monthKey]) {
      acc[monthKey] = {
        month: monthKey,
        commission: 0,
        sales: 0
      };
    }
    
    if (sale.agentId === agent.id) {
      acc[monthKey].commission += commission.agent;
      if (sale.bonus) {
        acc[monthKey].commission += commission.bonus.agent;
      }
    } else if (sale.referrerId === agent.id) {
      acc[monthKey].commission += commission.referrer || 0;
      if (sale.bonus?.secondAgentId === agent.id) {
        acc[monthKey].commission += commission.bonus.referrer;
      }
    }
    
    acc[monthKey].sales += 1;
    return acc;
  }, {} as Record<string, {
    month: string;
    commission: number;
    sales: number;
  }>);

  // Find best and worst months
  const monthlyStats = Object.values(monthlyData);
  const bestMonth = monthlyStats.length > 0 
    ? monthlyStats.reduce((best, current) => 
        current.commission > best.commission ? current : best
      )
    : { month: 'N/A', commission: 0, sales: 0 };

  const worstMonth = monthlyStats.length > 0
    ? monthlyStats.reduce((worst, current) => 
        current.commission < worst.commission ? current : worst
      )
    : { month: 'N/A', commission: 0, sales: 0 };

  const agentCommission = lastSale ? (
    lastSale.agentId === agent.id ? commission?.agent : commission?.referrer
  ) : 0;
  
  // Calculate total volume
  const totalVolume = agentSales.reduce((sum, sale) => sum + sale.propertyPrice, 0);

  // Calculate commission stats
  const stats = agentSales.reduce((acc, sale) => {
    const commission = calculateCommission(sale);
    const isMainAgent = sale.agentId === agent.id;
    let saleCommission = 0;
    let saleBonus = 0;

    // Calculate commission based on agent role
    if (isMainAgent) {
      saleCommission += commission.agent;
      if (sale.bonus) {
        saleBonus += commission.bonus.agent;
      }
    } else if (sale.referrerId === agent.id) {
      saleCommission += commission.referrer || 0;
      if (sale.bonus?.secondAgentId === agent.id) {
        saleBonus += commission.bonus.referrer;
      }
    } else if (sale.manualCommissions?.additionalAgents) {
      const additionalAgent = sale.manualCommissions.additionalAgents.find(a => a.agentId === agent.id);
      if (additionalAgent) {
        saleCommission += additionalAgent.amount;
        if (sale.bonus?.agents?.find(a => a.agentId === agent.id)) {
          saleBonus += sale.bonus.agents.find(a => a.agentId === agent.id)?.amount || 0;
        }
      }
    }

    // Determine payment status based on role
    const isPaid = isMainAgent ? sale.paymentStatus.agent : sale.paymentStatus.referrer;
    const isBonusPaid = sale.paymentStatus.bonus;

    if (isPaid) {
      acc.received += saleCommission;
      if (isBonusPaid) {
        acc.bonus += saleBonus;
      }
    } else {
      acc.pending += saleCommission;
      if (!isBonusPaid) {
        acc.pending += saleBonus;
      }
    }

    return acc;
  }, { received: 0, pending: 0, bonus: 0 });

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <div className="bg-[#1c1c1e]/90 backdrop-blur-xl w-full border-b border-[#2c2c2e]">
        <div className="max-w-lg mx-auto px-4 pt-12 pb-4">
          <div className="space-y-6">
            {/* Welcome Header */}
            <div className="space-y-1">
              <h1 className="text-[34px] font-bold text-[#ffffff]">
                Bienvenue,<br />
                {agent.name}
              </h1>
              </div>
            </div>
            
            {/* Stats */}
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2">
                  <TrendingUp className="w-5 h-5 text-[#30d158]" />
                  <span className="text-[17px] text-[#98989f] font-medium">Moyenne : {roundedMonthlyAverage} ventes/mois</span>
                  {averagePaymentDays && (
                    <span className="ml-4 px-3 py-1 bg-[#2c2c2e] rounded-xl text-[13px] text-[#98989f]">
                      Délai moyen de paiement : {averagePaymentDays} jours
                    </span>
                  )}
                </div>
            </div>
          </div>
        </div>

        {/* Monthly Stats Overview */}
        <div className="max-w-lg mx-auto px-4 py-4 border-t border-[#2c2c2e]">
          <div className="grid grid-cols-3 gap-4">
            {/* Monthly Sales */}
            <div className="p-4 bg-[#2c2c2e] rounded-xl">
              <div className="flex items-center gap-2 mb-2">
                <Building className="w-4 h-4 text-[#0a84ff]" />
                <p className="text-[13px] text-[#98989f]">Ventes ce mois</p>
              </div>
              <p className="text-[22px] font-bold text-white">{currentMonthSales.length}</p>
              <div className="flex items-center gap-1 mt-1">
                <TrendingUp className="w-4 h-4 text-[#30d158]" />
                <span className="text-[13px] text-[#30d158]">+{roundedMonthlyAverage} en moyenne</span>
              </div>
            </div>

            {/* Monthly Commission */}
            <div className="p-4 bg-[#2c2c2e] rounded-xl">
              <div className="flex items-center gap-2 mb-2">
                <DollarSign className="w-4 h-4 text-[#30d158]" />
                <p className="text-[13px] text-[#98989f]">Commission du mois</p>
              </div>
              <p className="text-[22px] font-bold text-[#30d158]">
                {formatCurrency(currentMonthCommission)}
              </p>
            </div>

            {/* Last Sale */}
            <div className="p-4 bg-[#2c2c2e] rounded-xl">
              <div className="flex items-center gap-2 mb-2">
                <Clock className="w-4 h-4 text-[#ff9f0a]" />
                <p className="text-[13px] text-[#98989f]">Dernière vente</p>
              </div>
              <p className="text-[15px] text-white">{daysSinceLastSale}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-lg mx-auto px-4 py-4 space-y-4">
        {/* Status Progress */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gradient-to-br from-[#1c3a4f] to-[#0a2540] rounded-xl overflow-hidden"
        >
          <div className="p-6">
            <div className="flex items-center gap-3 mb-6">
              <div className="w-10 h-10 rounded-xl bg-[#ffd60a]/10 flex items-center justify-center">
                <Crown className="w-6 h-6 text-[#ffd60a]" />
              </div>
              <div>
                <h3 className="text-[17px] font-medium text-white">Statut {status}</h3>
                <p className="text-[13px] text-[#98989f]">{totalSales} ventes au total</p>
              </div>
            </div>

            {nextStatus && (
              <div className="space-y-4">
                <div className="flex items-center justify-between text-sm">
                  <p className="text-[#98989f]">Progression vers {nextStatus}</p>
                  <p className="text-[#98989f]">{Math.round(progressToNextStatus)}%</p>
                </div>
                <div className="h-2 bg-[#3a3a3c] rounded-full overflow-hidden">
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: `${progressToNextStatus}%` }}
                    transition={{ duration: 1, ease: "easeOut" }}
                    className="h-full bg-[#ffd60a] rounded-full"
                  />
                </div>
                <div className="mt-4 p-4 bg-[#3a3a3c] rounded-xl">
                  <div className="flex items-center gap-2 mb-2">
                    <Star className="w-4 h-4 text-[#ffd60a]" />
                    <h4 className="text-[15px] font-medium text-white">Avantages {nextStatus}</h4>
                  </div>
                  <ul className="space-y-2 text-[13px] text-[#98989f]">
                    {nextStatus === 'Premium' ? (
                      <>
                        <li className="flex items-center gap-2">
                          <span className="w-1.5 h-1.5 rounded-full bg-[#ffd60a]" />
                          <span>Accès aux leads premium</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="w-1.5 h-1.5 rounded-full bg-[#ffd60a]" />
                          <span>Priorité sur les leads</span>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="flex items-center gap-2">
                          <span className="w-1.5 h-1.5 rounded-full bg-[#ffd60a]" />
                          <span>Accès aux leads Elite (Palm Jumeirah, Penthouses, Luxury)</span>
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="w-1.5 h-1.5 rounded-full bg-[#ffd60a]" />
                          <span>Priorité maximale sur les leads</span>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </motion.div>

        {/* Instagram Challenge */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gradient-to-br from-[#2d2a3e] to-[#1a1b1e] rounded-xl overflow-hidden"
        >
          <div className="p-6">
            <div className="flex items-center gap-3 mb-6">
              <div className="w-10 h-10 rounded-xl bg-[#bf5af2]/10 flex items-center justify-center">
                <Instagram className="w-6 h-6 text-[#bf5af2]" />
              </div>
              <div>
                <h3 className="text-[17px] font-medium text-white">Challenge Instagram</h3>
                <p className="text-[13px] text-[#98989f]">Gagnez 500€ ce mois-ci</p>
              </div>
            </div>

            <div className="space-y-4">
              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <h4 className="text-[15px] font-medium text-white mb-2">Objectifs mensuels</h4>
                <ul className="space-y-2 text-[13px] text-[#98989f]">
                  <li className="flex items-center gap-2">
                    <span className="w-1.5 h-1.5 rounded-full bg-[#bf5af2]" />
                    <span>20 stories</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="w-1.5 h-1.5 rounded-full bg-[#bf5af2]" />
                    <span>10 posts (reels ou images)</span>
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="w-1.5 h-1.5 rounded-full bg-[#bf5af2]" />
                    <span>Mention @palmdubai.fr obligatoire</span>
                  </li>
                </ul>
              </div>

              <button
                className="w-full flex items-center justify-between px-4 py-3 bg-[#bf5af2] text-white rounded-xl hover:bg-[#bf5af2]/90 transition-colors"
              >
                <div className="flex items-center gap-3">
                  <Trophy className="w-5 h-5" />
                  <span className="text-[15px] font-medium">Participer au challenge</span>
                </div>
                <ChevronRight className="w-5 h-5" />
              </button>
            </div>
          </div>
        </motion.div>

        {/* Quarterly Goal Card */}
        {quarterlyGoal && (
          <MonthlyGoalCard
            quarterlyGoal={quarterlyGoal}
            sales={agentSales}
          />
        )}

        {/* Alerts */}
        <InactivityAlert lastSaleDate={lastSale?.saleDate} />
        <UnderperformingAlert monthlyAverage={roundedMonthlyAverage} />
        
        {/* Last Sale */}
        {lastSale && agentCommission && (
          <LastSaleCard 
            sale={lastSale}
            commission={agentCommission}
          />
        )}
        
        {/* Commission Stats */}
        <CommissionStats
          stats={stats}
          totalVolume={totalVolume}
          salesCount={agentSales.length}
        />

        {/* Monthly Commission History */}
        <MonthlyCommissionHistory
          sales={agentSales}
          agentId={agent.id}
        />

        {/* Performance & AI Estimations */}
        <PerformanceSection
          bestMonth={bestMonth}
          worstMonth={worstMonth}
          stats={{
            monthlyAverageSales: monthlyAverage,
            monthlyAverageRevenue: totalVolume / Math.max(1, Object.keys(monthlyData).length),
            monthlyAverageCommission: (stats.received + stats.pending) / Math.max(1, Object.keys(monthlyData).length),
            growthRate: {
              sales: ((monthlyAverage - (monthlyAverage * 0.9)) / (monthlyAverage * 0.9)) * 100,
              revenue: 15, // Example growth rate
              commission: 12 // Example growth rate
            }
          }}
          projections={{
            nextMonth: {
              sales: Math.ceil(monthlyAverage * 1.1),
              revenue: totalVolume / Math.max(1, Object.keys(monthlyData).length) * 1.1,
              commission: (stats.received + stats.pending) / Math.max(1, Object.keys(monthlyData).length) * 1.1
            },
            threeMonths: {
              sales: Math.ceil(monthlyAverage * 3 * 1.2),
              revenue: totalVolume / Math.max(1, Object.keys(monthlyData).length) * 3 * 1.2,
              commission: (stats.received + stats.pending) / Math.max(1, Object.keys(monthlyData).length) * 3 * 1.2
            },
            endOfYear: {
              sales: Math.ceil(monthlyAverage * (12 - new Date().getMonth()) * 1.3),
              revenue: totalVolume / Math.max(1, Object.keys(monthlyData).length) * (12 - new Date().getMonth()) * 1.3,
              commission: (stats.received + stats.pending) / Math.max(1, Object.keys(monthlyData).length) * (12 - new Date().getMonth()) * 1.3
            }
          }}
        />
        
        {/* Quarterly Ranking */}
        <QuarterlyRanking />

        {/* Referral Sales Section */}
        <ReferralSalesSection
          sales={sales}
          calculateCommission={calculateCommission}
          agentId={agent.id}
          agents={agents}
        />

        {/* Estimation IA Button */}
        <Link
          to={`/agentcrm/${slug}/estimation`}
          className="mt-6 flex items-center justify-between w-full px-4 py-3 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors"
        >
          <div className="flex items-center gap-3">
            <Calculator className="w-5 h-5" />
            <span className="text-[15px] font-medium">Voir l'estimation détaillée</span>
          </div>
          <ChevronRight className="w-5 h-5" />
        </Link>

        {/* Exclusive Rewards */}
        <ExclusiveRewards />

        {/* Referral Banner */}
        <ReferralBanner />

        {/* Virtual Business Card */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-gradient-to-br from-[#1c3a4f] to-[#0a2540] rounded-xl overflow-hidden"
        >
          <div className="p-4 border-b border-[#3a3a3c]">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                <Globe className="w-6 h-6 text-[#0a84ff]" />
              </div>
              <div>
                <h3 className="text-[17px] font-medium text-white">Carte de visite virtuelle</h3>
                <p className="text-[13px] text-[#98989f]">Partagez vos coordonnées facilement</p>
              </div>
            </div>
          </div>

          <div className="p-6">
            <VirtualBusinessCard agent={{
              name: agent.name,
              email: agent.email,
              phone: agent.phone
            }} />
          </div>
        </motion.div>

        {/* Quick Actions */}
        <div className="p-4 bg-gradient-to-br from-[#2c2c2e] to-[#1c1c1e] rounded-xl">
          <h3 className="text-[17px] font-medium text-white mb-4">Actions rapides</h3>
          <div className="grid grid-cols-2 gap-4">
            <Link
              to={`/agentcrm/${slug}/sales`}
              className="flex items-center justify-center gap-2 px-4 py-3 bg-gradient-to-br from-[#3a3a3c] to-[#2c2c2e] text-[#FFF] rounded-xl hover:from-[#434345] hover:to-[#3a3a3c] transition-colors"
            >
              <Building className="w-4 h-4" />
              <span className="text-[15px] font-medium">Mes ventes</span>
            </Link>
            <Link
              to={`/agentcrm/${slug}/estimation`}
              className="flex items-center justify-center gap-2 px-4 py-3 bg-gradient-to-br from-[#0a84ff] to-[#0070e0] text-white rounded-xl hover:from-[#0a84ff]/90 hover:to-[#0070e0]/90 transition-colors"
            >
              <Target className="w-4 h-4" />
              <span className="text-[15px] font-medium">Estimation IA</span>
            </Link>
          </div>
        </div>

        {/* Bottom Safe Area */}
        <div className="h-8" />
      </div>
    </div>
  );
}