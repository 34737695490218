import React from 'react';
import { motion } from 'framer-motion';
import { X, DollarSign, Calendar, Gift } from 'lucide-react';
import { formatCurrency, formatEuro } from '../../utils/format';
import { formatDateDisplay } from '../../utils/date';
import BlurredNumber from '../ui/BlurredNumber';
import type { Sale } from '../../types';

interface CommissionPopupProps {
  onClose: () => void;
  sales: Sale[];
  calculateCommission: (sale: Sale) => any;
}

export default function CommissionPopup({ onClose, sales, calculateCommission }: CommissionPopupProps) {
  // Calculate total pending commission
  const totalPending = sales.reduce((total, sale) => {
    if (!sale.paymentStatus.developer) {
      const commission = calculateCommission(sale);
      return total + commission.agency + (sale.bonus ? commission.bonus.agency : 0);
    }
    return total;
  }, 0);

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        className="bg-[#1c1c1e] rounded-2xl w-full max-w-2xl m-4"
      >
        {/* Header */}
        <div className="p-6 border-b border-[#2c2c2e]">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                <DollarSign className="w-6 h-6 text-[#0a84ff]" />
              </div>
              <div>
                <h2 className="text-[22px] font-semibold text-white">Commissions en attente</h2>
                <p className="text-[13px] text-[#98989f]">
                  Total : <BlurredNumber value={formatCurrency(totalPending)} />
                </p>
              </div>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-[#2c2c2e] rounded-xl transition-colors"
            >
              <X className="w-5 h-5 text-[#98989f]" />
            </button>
          </div>
        </div>

        {/* Sales List */}
        <div className="max-h-[60vh] overflow-y-auto">
          {sales.filter(sale => !sale.paymentStatus.developer).map(sale => {
            const commission = calculateCommission(sale);
            const agencyCommission = commission.agency + (sale.bonus ? commission.bonus.agency : 0);

            return (
              <div key={sale.id} className="p-4 border-b border-[#2c2c2e]">
                <div className="flex items-start justify-between">
                  <div>
                    <h3 className="text-[17px] font-medium text-white">{sale.project}</h3>
                    <p className="text-[15px] text-[#98989f]">Unité {sale.unitNumber}</p>
                    <div className="flex items-center gap-2 mt-2">
                      <Calendar className="w-4 h-4 text-[#0a84ff]" />
                      <span className="text-[13px] text-[#98989f]">
                        {formatDateDisplay(sale.saleDate)}
                      </span>
                    </div>
                  </div>
                  <div className="text-right">
                    <p className="text-[17px] font-bold text-white">
                      <BlurredNumber value={formatCurrency(commission.agency)} />
                    </p>
                    <p className="text-[13px] text-[#98989f]">
                      <BlurredNumber value={formatEuro(commission.agency)} />
                    </p>
                    {sale.bonus && (
                      <div className="flex items-center gap-1 mt-2 text-[13px] text-[#bf5af2]">
                        <Gift className="w-4 h-4" />
                        +<BlurredNumber value={formatCurrency(commission.bonus.agency)} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
}