import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Gift, Target, Calculator, ImageIcon as ImageIcon, Trophy, Sparkles, Target as TargetIcon, Zap, Car, Watch, ShoppingBag, AlertTriangle } from 'lucide-react';
import { useSales } from '../../hooks/useSales';
import { useAgentSlug } from '../../hooks/useAgentSlug';
import { formatCurrency } from '../../utils/format';
import BlurredNumber from '../../components/ui/BlurredNumber';

export default function ExclusiveRewards() {
  const { agent } = useAgentSlug();
  const { sales, calculateCommission } = useSales();

  if (!agent) return null;

  // Calculate total agency commission
  const totalAgencyCommission = sales
    .filter(sale => sale.agentId === agent.id || sale.referrerId === agent.id)
    .reduce((total, sale) => {
      const commission = calculateCommission(sale);
      return total + commission.agency + (sale.bonus ? commission.bonus.agency : 0);
    }, 0);

  // Reward thresholds and details
  const REWARDS = [
    {
      id: 'watch',
      name: 'Montre de luxe',
      threshold: 600000, // 600,000 AED
      icon: Watch,
      color: '#ffd60a',
      description: 'Montre au choix (valeur ≃ 40 000 AED)',
      details: 'Bénéfice Palm de 600,000 AED requis'
    },
    {
      id: 'lv',
      name: 'Louis Vuitton',
      threshold: 800000, // 800,000 AED
      icon: ShoppingBag,
      color: '#bf5af2',
      description: 'Carte ou sac Louis Vuitton (valeur ≃ 40 000 AED)',
      details: 'Bénéfice Palm de 800,000 AED requis'
    },
    {
      id: 'car1',
      name: 'Première voiture',
      threshold: 2000000, // 2,000,000 AED
      icon: Car,
      color: '#0a84ff',
      description: 'Véhicule type Mercedes C / BMW Série 3 ou similaire',
      details: 'Bénéfice Palm de 2,000,000 AED requis'
    },
    {
      id: 'car2',
      name: 'Voiture premium',
      threshold: 4000000, // 4,000,000 AED
      icon: Car,
      color: '#30d158',
      description: 'Véhicule type Mercedes S / GLE / Cayenne ou similaire',
      details: 'Bénéfice Palm de 4,000,000 AED requis'
    }
  ];

  const handleContact = (reward: typeof REWARDS[0]) => {
    const message = encodeURIComponent(
      `Bonjour, j'ai atteint l'objectif pour ${reward.name} ! Je souhaiterais en discuter avec vous.`
    );
    window.open(`https://wa.me/971581998661?text=${message}`, '_blank');
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-[#2c2c2e] rounded-xl overflow-hidden"
    >
      <div className="p-6">
        <div className="flex items-center gap-3 mb-6">
          <div className="w-10 h-10 rounded-xl bg-[#ffd60a]/10 flex items-center justify-center">
            <Gift className="w-6 h-6 text-[#ffd60a]" />
          </div>
          <div>
            <h3 className="text-[17px] font-medium text-white">Récompenses Exclusives</h3>
            <p className="text-[13px] text-[#98989f]">Bénéfice Palm actuel : {formatCurrency(totalAgencyCommission)}</p>
          </div>
        </div>

        <div className="space-y-4">
          {REWARDS.map((reward, index) => (
            <motion.div
              key={reward.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className={`p-4 bg-[#3a3a3c] rounded-xl ${
                totalAgencyCommission >= reward.threshold ? 'border border-[#30d158]/20' : ''
              }`}
            >
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center gap-2">
                  <div className={`w-2 h-2 rounded-full bg-[${reward.color}]`} />
                  <span className="text-[15px] text-white">{reward.name}</span>
                </div>
                <div className="flex gap-2">
                  {totalAgencyCommission >= reward.threshold ? (
                    <div className="px-2 py-0.5 bg-[#26382f] text-[#30d158] rounded-full">
                      Débloqué !
                    </div>
                  ) : (
                    <div className="px-2 py-0.5 bg-[#3a2c29] text-[#ff453a] rounded-full">
                      {((totalAgencyCommission / reward.threshold) * 100).toFixed(1)}%
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-2">
                <div className="h-1.5 bg-[#2c2c2e] rounded-full overflow-hidden">
                  <motion.div
                    initial={{ width: 0 }}
                    animate={{ width: `${Math.min((totalAgencyCommission / reward.threshold) * 100, 100)}%` }}
                    transition={{ duration: 1, ease: "easeOut" }}
                    className={`h-full bg-[${reward.color}] rounded-full`}
                  />
                </div>
              </div>

              <div className="mt-4 space-y-2">
                <div className="flex items-center gap-2">
                  <Target className="w-4 h-4 text-[#98989f]" />
                  <span className="text-[13px] text-[#98989f]">
                    <BlurredNumber value={formatCurrency(reward.threshold)} />
                  </span>
                </div>
                <p className="text-[13px] text-[#98989f]">{reward.description}</p>
              </div>

              {reward.id.startsWith('car') && (
                <div className="mt-4 p-3 bg-[#2c2c2e] rounded-xl">
                  <p className="text-[13px] text-[#98989f] leading-relaxed">
                    <strong className="text-white">Clause véhicule :</strong><br />
                    • 12 mois d'activité avec min. 2 ventes/mois ou 24 ventes/an<br />
                    • Véhicule appartenant à Palm Signature pendant 12 mois<br />
                    • Transfert de propriété après 12 mois si conditions remplies<br />
                    • Assurance, essence, entretien à charge de l'agent<br />
                    • Restitution obligatoire si départ/manquement<br />
                    • Pas d'upgrade pendant 12 mois
                  </p>
                </div>
              )}

              {totalAgencyCommission >= reward.threshold && (
                <button
                  onClick={() => handleContact(reward)}
                  className="mt-4 w-full px-4 py-3 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors flex items-center justify-center gap-2"
                >
                  <Gift className="w-4 h-4" />
                  Réclamer la récompense
                </button>
              )}
            </motion.div>
          ))}
        </div>

        <div className="mt-6 p-4 bg-[#3a2c29] rounded-xl">
          <div className="flex items-center gap-2 mb-2">
            <AlertTriangle className="w-5 h-5 text-[#ff453a]" />
            <p className="text-[15px] font-medium text-[#ff453a]">Important</p>
          </div>
          <p className="text-[13px] text-[#98989f]">
            Les bonus sont calculés sur une durée de 12 mois à partir de votre première vente.
          </p>
        </div>
      </div>
    </motion.div>
  );
}