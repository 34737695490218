import React from 'react';
import { Mail, Phone, Globe } from 'lucide-react';

interface VirtualBusinessCardProps {
  agent: {
    name: string;
    email: string;
    phone?: string;
  };
}

export default function VirtualBusinessCard({ agent }: VirtualBusinessCardProps) {
  return (
    <div>
      {/* Business Card */}
      <div className="relative w-full bg-gradient-to-br from-white to-gray-50 rounded-2xl shadow-xl overflow-hidden p-8 mb-4">
        {/* Logo */}
        <div className="relative mb-6">
          <img
            src="https://palmdubai.fr/templates/Default/img/logo_black.png"
            alt="Palm Dubai Logo"
            className="h-10 w-auto"
          />
        </div>

        {/* Agent Info */}
        <div className="relative space-y-6">
          <div>
            <h2 className="text-xl font-bold text-gray-900">{agent.name}</h2>
            <p className="text-xs text-gray-500">Agent Immobilier - Dubai</p>
          </div>

          <div className="space-y-3">
            <a
              href={`mailto:${agent.email}`}
              className="flex items-center gap-2 text-sm text-gray-600"
            >
              <Mail className="w-4 h-4" />
              <span>{agent.email}</span>
            </a>
            {agent.phone && (
              <a
                href={`tel:${agent.phone}`}
                className="flex items-center gap-2 text-sm text-gray-600"
              >
                <Phone className="w-4 h-4" />
                <span>{agent.phone}</span>
              </a>
            )}
            <a
              href="https://palmdubai.fr"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 text-sm text-gray-600"
            >
              <Globe className="w-4 h-4" />
              <span>www.palmdubai.fr</span>
            </a>
          </div>
        </div>

        {/* Decorative Elements */}
        <div className="absolute bottom-0 right-0 w-32 h-32 bg-gradient-to-br from-blue-500/5 to-purple-500/5 rounded-full blur-2xl" />
        <div className="absolute top-0 left-0 w-32 h-32 bg-gradient-to-br from-yellow-500/5 to-red-500/5 rounded-full blur-2xl" />
      </div>

      {/* Request Printed Card Button */}
      <button
        onClick={() => {
          const message = encodeURIComponent(
            `Bonjour,\n\n` +
            `Je souhaite commander des cartes de visite avec les informations suivantes :\n\n` +
            `Nom : ${agent.name}\n` +
            `Email : ${agent.email}\n` +
            `Téléphone : ${agent.phone || 'Non renseigné'}\n\n` +
            `Merci !`
          );
          window.open(`https://wa.me/971581998661?text=${message}`, '_blank');
        }}
        className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors"
      >
        <Mail className="w-5 h-5" />
        <span className="text-[15px] font-medium">Demander ma carte de visite imprimée</span>
      </button>
    </div>
  );
}