import React, { useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import KPICards from '../components/analytics/KPICards';
import TopAgents from '../components/analytics/TopAgents';
import TopProjects from '../components/analytics/TopProjects'; 
import UnderperformingAgents from '../components/analytics/UnderperformingAgents';
import LastSaleCard from '../components/LastSaleCard';
import AgencyRevenueHistory from '../components/analytics/AgencyRevenueHistory';
import PerformanceChart from '../components/analytics/PerformanceChart';
import { Building, Bell, DollarSign } from 'lucide-react';
import { useSales } from '../hooks/useSales';
import { useAgents } from '../hooks/useAgents';
import CommissionPopup from '../components/analytics/CommissionPopup';
import { useAuth } from '../hooks/useAuth';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import BulkNotificationForm from '../components/admin/BulkNotificationForm';
import { formatCurrency, formatEuro } from '../utils/format';
import BlurredNumber from '../components/ui/BlurredNumber';

export default function Dashboard() {
  const { sales, calculateCommission } = useSales();
  const { agents } = useAgents();
  const [showCommissionPopup, setShowCommissionPopup] = useState(false);
  const startDate = new Date(2024, 6); // July 2024
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const { user } = useAuth();

  // Calculate total volume and commission
  const totals = useMemo(() => {
    const result = sales.reduce((acc, sale) => {
      try {
        const commission = calculateCommission(sale);
        return {
          volume: acc.volume + (sale.propertyPrice || 0),
          commission: acc.commission + commission.agency + (sale.bonus ? commission.bonus.agency : 0)
        };
      } catch (error) {
        console.error('Error calculating commission for sale:', sale.id, error);
        return acc;
      }
    }, { volume: 0, commission: 0 });
    return result;
  }, [sales, calculateCommission]);

  // Get the most recent sale
  const lastSale = sales.length > 0 
    ? sales.sort((a, b) => new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime())[0]
    : null;

  // Calculate days since last sale
  const daysSinceLastSale = lastSale 
    ? formatDistanceToNow(new Date(lastSale.saleDate), { locale: fr, addSuffix: true })
    : 'Aucune vente';

  // Calculate monthly revenue data
  const revenueHistory = useMemo(() => {
    const now = new Date();
    const filterDate = new Date();
    filterDate.setMonth(now.getMonth() - 12); // Last 12 months

    // Filter sales based on date range
    const filteredSales = sales.filter(sale => {
      const saleDate = new Date(sale.saleDate);
      return saleDate >= filterDate && saleDate <= now;
    });

    // Group filtered sales by month
    const monthlyData = filteredSales.reduce((acc, sale) => {
      const date = new Date(sale.saleDate);
      const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
      const commission = calculateCommission(sale);
      const totalCommission = commission.agency + (sale.bonus ? commission.bonus.agency : 0);
      
      if (!acc[monthKey]) {
        acc[monthKey] = { 
          month: monthKey,
          revenue: 0
        };
      }
      
      acc[monthKey].revenue += totalCommission;
      
      return acc;
    }, {} as Record<string, { 
      month: string;
      revenue: number;
    }>);

    // Get all months in range
    const months = [];
    const currentDate = new Date();
    for (let i = 11; i >= 0; i--) {
      const date = new Date();
      date.setMonth(currentDate.getMonth() - i);
      const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
      months.push({
        month: monthKey,
        revenue: monthlyData[monthKey]?.revenue || 0
      });
    }

    // Calculate progression
    return months.map((month, index) => {
      let progression = 0;
      if (index > 0) {
        const previousRevenue = months[index - 1].revenue;
        if (previousRevenue > 0) {
          progression = ((month.revenue - previousRevenue) / previousRevenue) * 100;
        }
      }
      return { ...month, progression };
    });
  }, [sales, calculateCommission]);

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <div className="bg-[#1c1c1e]/90 backdrop-blur-xl sticky top-0 z-50 w-full border-b border-[#2c2c2e]">
        <div className="max-w-7xl mx-auto px-4 py-3">
          <div className="flex flex-col sm:flex-row sm:items-center gap-4">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                <Building className="w-6 h-6 text-[#0a84ff]" />
              </div>
              <div>
                <h1 className="text-[22px] font-semibold text-white">Dashboard</h1>
                <p className="text-[13px] text-[#98989f]">Vue d'ensemble</p>
              </div>
            </div>
            <div className="flex items-center gap-4 ml-auto">
              <div className="flex flex-col items-end">
                <p className="text-[13px] text-[#98989f]">Volume total</p>
                <p className="text-[17px] font-bold text-white">
                  <BlurredNumber value={formatCurrency(totals.volume)} />
                </p>
              </div>
              <div className="flex flex-col items-end">
                <p className="text-[13px] text-[#98989f]">Commission agence</p>
                <button
                  onClick={() => setShowCommissionPopup(true)}
                  className="text-[17px] font-bold text-[#30d158] hover:text-[#30d158]/80 transition-colors"
                >
                  <BlurredNumber value={formatCurrency(totals.commission)} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-4 space-y-6">
        {/* KPI Cards */}
        <KPICards />

        {/* Last Sale Card */}
        {lastSale && <LastSaleCard sale={lastSale} />}

        {/* Performance Chart */}
        <PerformanceChart />

        {/* Monthly Revenue Chart */}
        <AgencyRevenueHistory data={revenueHistory} sales={sales} />

        {/* Performance Charts */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <TopAgents />
          <TopProjects />
        </div>

        {/* Underperforming Agents */}
        <UnderperformingAgents />

        {/* Bottom Safe Area */}
        <div className="h-8" />
      </div>

      {/* Commission Popup */}
      {showCommissionPopup && (
        <CommissionPopup
          onClose={() => setShowCommissionPopup(false)}
          sales={sales}
          calculateCommission={calculateCommission}
        />
      )}
    </div>
  );
}