import type { Sale } from '../types';

export const COMMISSION_TYPES = {
  standard: { agent: 0.60, agency: 0.40 },
  shared: { agent: 0.50, agency: 0.50 },
  mlm: {
    // Taux de commission par niveau MLM
    bronze: { personal: 0.50, override1: 0.10, override2: 0.05, override3: 0.03 },
    silver: { personal: 0.55, override1: 0.12, override2: 0.07, override3: 0.04 },
    gold: { personal: 0.60, override1: 0.15, override2: 0.10, override3: 0.05 },
    platinum: { personal: 0.65, override1: 0.17, override2: 0.12, override3: 0.07 },
    diamond: { personal: 0.70, override1: 0.20, override2: 0.15, override3: 0.10 }
  }
} as const;

export const MLM_RANKS: MLMRank[] = [
  {
    name: 'Bronze',
    minPersonalVolume: 1000000,
    minTeamVolume: 3000000,
    minDirectRecruits: 2,
    commissionRate: 0.50,
    overrideRate: 0.10,
    color: '#CD7F32'
  },
  {
    name: 'Silver',
    minPersonalVolume: 2000000,
    minTeamVolume: 6000000,
    minDirectRecruits: 3,
    commissionRate: 0.55,
    overrideRate: 0.12,
    color: '#C0C0C0'
  },
  {
    name: 'Gold',
    minPersonalVolume: 3000000,
    minTeamVolume: 10000000,
    minDirectRecruits: 4,
    commissionRate: 0.60,
    overrideRate: 0.15,
    color: '#FFD700'
  },
  {
    name: 'Platinum',
    minPersonalVolume: 5000000,
    minTeamVolume: 20000000,
    minDirectRecruits: 5,
    commissionRate: 0.65,
    overrideRate: 0.17,
    color: '#E5E4E2'
  },
  {
    name: 'Diamond',
    minPersonalVolume: 10000000,
    minTeamVolume: 50000000,
    minDirectRecruits: 7,
    commissionRate: 0.70,
    overrideRate: 0.20,
    color: '#B9F2FF'
  }
];

export function calculateMLMCommission(sale: Sale, agent: Agent, upline: Agent[]) {
  const commission = {
    personal: 0,
    override: 0,
    total: 0
  };

  // Calcul de la commission personnelle
  const rank = MLM_RANKS.find(r => r.name === agent.rank) || MLM_RANKS[0];
  commission.personal = sale.developerCommission * rank.commissionRate;

  // Calcul des overrides pour la lignée ascendante
  upline.forEach((uplineAgent, index) => {
    if (index < 3) { // Maximum 3 niveaux d'override
      const uplineRank = MLM_RANKS.find(r => r.name === uplineAgent.rank) || MLM_RANKS[0];
      const overrideRate = COMMISSION_TYPES.mlm[uplineRank.name.toLowerCase()][`override${index + 1}`];
      commission.override += sale.developerCommission * overrideRate;
    }
  });

  commission.total = commission.personal + commission.override;
  return commission;
}

export function calculateCommission(sale: Sale) {
  // Base commission object
  let commission = {
    agent: 0,
    agency: 0,
    referrer: 0,
    bonus: {
      agent: 0,
      agency: 0,
      referrer: 0
    }
  };

  const totalCommission = sale.developerCommission;

  // Calculate main commission
  if (sale.commissionType === 'manual' && sale.manualCommissions) {
    // Use manual amounts directly
    commission.agent = sale.manualCommissions.agent;
    commission.agency = sale.manualCommissions.agency;

    // Handle additional agents
    if (sale.manualCommissions.additionalAgents) {
      commission.additionalAgents = sale.manualCommissions.additionalAgents.map(agent => {
        return {
          agentId: agent.agentId,
          amount: agent.amount
        };
      });

      // If referrer is among additional agents, set referrer commission
      const referrerAgent = sale.manualCommissions.additionalAgents.find(a => a.agentId === sale.referrerId);
      if (referrerAgent) {
        commission.referrer = referrerAgent.amount;
      }
    }
  } else {
    // Standard commission calculation with fallback to standard type
    const commissionType = COMMISSION_TYPES[sale.commissionType] || COMMISSION_TYPES.standard;

    commission.agent = totalCommission * commissionType.agent;
    commission.agency = totalCommission * commissionType.agency;

    if (commissionType.referrer && sale.referrerId) {
      commission.referrer = totalCommission * commissionType.referrer;
    }
  }

  // Calculate bonus if exists
  if (sale.bonus) {
    const bonusAmount = sale.bonus.amount;
    
    if (sale.bonus.isManual) {
      // Use manual bonus distribution
      const agentBonus = sale.bonus.agents?.find(a => a.agentId === sale.agentId)?.amount || 0;
      const referrerBonus = sale.bonus.agents?.find(a => a.agentId === sale.referrerId)?.amount || 0;
      commission.bonus = {
        agent: agentBonus,
        referrer: referrerBonus,
        agency: sale.bonus.agencyAmount || 0
      };
    } else if (sale.bonus.secondAgentId) {
      // Three-way split for bonus (30/30/40)
      commission.bonus = {
        agent: bonusAmount * 0.3,
        referrer: bonusAmount * 0.3,
        agency: bonusAmount * 0.4
      };
    } else {
      // Standard split for bonus (60/40)
      commission.bonus = {
        agent: bonusAmount * 0.6,
        agency: bonusAmount * 0.4,
        referrer: 0
      };
    }
  }

  return commission;
}

export function getCommissionTypes() {
  return [
    { value: 'direct_lead', label: 'Lead direct (85/15)' }, // First sale 15%
    { value: 'standard', label: 'Standard (60/40)' },
    { value: 'terra_elite_70', label: 'Terra Elite (70/30)' },
    { value: 'shared', label: 'Partagée (50/50)' },
    { value: 'shared_three_way', label: 'Trois parties (30/30/40)' },
    { value: 'referral', label: 'Parrainage (50/40/10)' },
    { value: 'direct_lead_referral', label: 'Lead direct + Parrain (80/15/5)' },
    { value: 'terra_elite', label: 'Terra Elite (60/40 sur 5%)' },
    { value: 'manual', label: 'Manuel' }
  ];
}