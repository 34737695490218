import React from 'react';
import { Building, Calendar } from 'lucide-react';
import Autocomplete from '../ui/Autocomplete';
import { useAutocomplete } from '../../hooks/useAutocomplete';

interface PropertySectionProps {
  formData: {
    project: string;
    unitNumber: string;
    expectedDeliveryYear: number;
    saleDate: string;
    leadSource: string;
  };
  onChange: (data: Partial<PropertySectionProps['formData']>) => void;
}

export default function PropertySection({ formData, onChange }: PropertySectionProps) {
  const { projects } = useAutocomplete();

  const leadSources = [
    'Direct',
    'Référence',
    'Site web',
    'Instagram',
    'Facebook',
    'LinkedIn',
    'Property Finder',
    'Bayut',
    'Autre'
  ];

  return (
    <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
      {/* Header */}
      <div className="p-4 border-b border-[#3a3a3c]">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-[#0a84ff]/10 rounded-lg">
            <Building className="w-5 h-5 text-[#0a84ff]" />
          </div>
          <div>
            <h3 className="text-[17px] font-medium text-white">Information du bien</h3>
            <p className="text-[13px] text-[#98989f]">Détails du projet et de l'unité</p>
          </div>
        </div>
      </div>

      {/* Form Content */}
      <div className="p-4 space-y-6">
        {/* Project Name */}
        <div>
          <Autocomplete
            label="Projet"
            value={formData.project}
            onChange={(value) => onChange({ project: value })}
            suggestions={projects}
            placeholder="Sélectionner un projet"
            required
          />
        </div>

        {/* Unit Number */}
        <div>
          <label className="block text-[13px] font-medium text-[#98989f] mb-2">
            Numéro d'unité
            <span className="text-[#ff453a] ml-1">*</span>
          </label>
          <input
            type="text"
            required
            value={formData.unitNumber}
            onChange={(e) => onChange({ unitNumber: e.target.value })}
            className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
            placeholder="Ex: A-123"
          />
        </div>

        {/* Lead Source */}
        <div>
          <label className="block text-[13px] font-medium text-[#98989f] mb-2">
            Source du lead
            <span className="text-[#ff453a] ml-1">*</span>
          </label>
          <select
            required
            value={formData.leadSource}
            onChange={(e) => onChange({ leadSource: e.target.value })}
            className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
          >
            <option value="" className="bg-[#1c1c1e] text-white">Sélectionner une source</option>
            {leadSources.map(source => (
              <option key={source} value={source} className="bg-[#1c1c1e] text-white">
                {source}
              </option>
            ))}
          </select>
        </div>

        {/* Dates Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {/* Delivery Year */}
          <div>
            <label className="block text-[13px] font-medium text-[#98989f] mb-2">
              Année de livraison
              <span className="text-[#ff453a] ml-1">*</span>
            </label>
            <div className="relative">
              <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
              <input
                type="number"
                required
                min={new Date().getFullYear()}
                value={formData.expectedDeliveryYear}
                onChange={(e) => onChange({ expectedDeliveryYear: parseInt(e.target.value) })}
                className="w-full pl-10 pr-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff]"
              />
            </div>
          </div>

          {/* Sale Date */}
          <div>
            <label className="block text-[13px] font-medium text-[#98989f] mb-2">
              Date de vente
              <span className="text-[#ff453a] ml-1">*</span>
            </label>
            <div className="relative">
              <Calendar className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-[#98989f]" />
              <input
                type="date"
                required
                value={formData.saleDate}
                onChange={(e) => onChange({ saleDate: e.target.value })}
                className="w-full pl-10 pr-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}