import React, { useState } from 'react';
import { useSettings } from '../hooks/useSettings';
import { useAgents } from '../hooks/useAgents';
import { useToast } from '../contexts/ToastContext';
import { motion } from 'framer-motion';
import { 
  Settings as SettingsIcon, Bell, Gift, Users, 
  ChevronRight, Mail, Globe, Building, DollarSign,
  Target, Save, Database, Shield
} from 'lucide-react';
import ManualBonusForm from '../components/admin/ManualBonusForm';
import { formatCurrency } from '../utils/format';

export default function Settings() {
  const { settings, updateSettings } = useSettings();
  const { agents } = useAgents();
  const { showToast } = useToast();
  const [showBonusForm, setShowBonusForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [formData, setFormData] = useState({
    name: settings?.name || 'PalmCRM',
    email: settings?.email || 'contact@palmdubaiimmo.com',
    notifications: {
      newSale: true,
      commissionReceived: true,
      agentCreated: true,
      loginAttempt: true
    },
    emailjs: {
      serviceId: settings?.emailjs?.serviceId || '',
      templateId: settings?.emailjs?.templateId || '',
      publicKey: settings?.emailjs?.publicKey || '',
    },
    monthlyGoal: settings?.monthlyGoal || {
      volumeTarget: 3500000,
      reward: {
        type: 'car_rental',
        description: '2 mois de location de voiture offerts 🚗'
      }
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);

    try {
      await updateSettings({
        name: formData.name,
        email: formData.email,
        notifications: formData.notifications,
        emailjs: formData.emailjs,
        monthlyGoal: formData.monthlyGoal
      });
      
      showToast('success', 'Paramètres enregistrés avec succès');
    } catch (error) {
      console.error('Error saving settings:', error);
      showToast('error', 'Erreur lors de la sauvegarde');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <div className="bg-[#1c1c1e]/90 backdrop-blur-xl w-full border-b border-[#2c2c2e] sticky top-0 z-50">
        <div className="max-w-lg mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                <SettingsIcon className="w-6 h-6 text-[#0a84ff]" />
              </div>
              <div>
                <h2 className="text-[22px] font-semibold text-white">Paramètres</h2>
                <p className="text-[13px] text-[#98989f]">Configuration du système</p>
              </div>
            </div>
            <button
              onClick={() => setShowBonusForm(true)}
              className="px-4 py-2 bg-[#bf5af2]/10 text-[#bf5af2] rounded-xl hover:bg-[#bf5af2]/20 transition-colors flex items-center gap-2"
            >
              <Gift className="w-5 h-5" />
              Ajouter un bonus
            </button>
          </div>
        </div>
      </div>

      <div className="max-w-lg mx-auto px-4 py-4">
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* General Settings */}
          <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
            <div className="p-4 border-b border-[#3a3a3c]">
              <div className="flex items-center gap-3">
                <Building className="w-5 h-5 text-[#0a84ff]" />
                <h3 className="text-[17px] font-medium text-white">Général</h3>
              </div>
            </div>

            <div className="p-4 space-y-4">
              <div>
                <label className="block text-[13px] text-[#98989f] mb-2">
                  Nom de l'application
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                />
              </div>

              <div>
                <label className="block text-[13px] text-[#98989f] mb-2">
                  Email de contact
                </label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                />
              </div>
            </div>
          </div>

          {/* Notifications */}
          <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
            <div className="p-4 border-b border-[#3a3a3c]">
              <div className="flex items-center gap-3">
                <Bell className="w-5 h-5 text-[#0a84ff]" />
                <h3 className="text-[17px] font-medium text-white">Notifications</h3>
              </div>
            </div>

            <div className="p-4 space-y-4">
              {Object.entries({
                newSale: 'Nouvelle vente',
                commissionReceived: 'Commission reçue',
                agentCreated: 'Nouvel agent',
                loginAttempt: 'Tentative de connexion'
              }).map(([key, label]) => (
                <div key={key} className="flex items-center justify-between p-4 bg-[#3a3a3c] rounded-xl">
                  <span className="text-[15px] text-white">{label}</span>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={formData.notifications[key as keyof typeof formData.notifications]}
                      onChange={(e) => setFormData({
                        ...formData,
                        notifications: {
                          ...formData.notifications,
                          [key]: e.target.checked
                        }
                      })}
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-[#48484a] peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#0a84ff]/30 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-[#48484a] after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#0a84ff]"></div>
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* Monthly Goals */}
          <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
            <div className="p-4 border-b border-[#3a3a3c]">
              <div className="flex items-center gap-3">
                <Target className="w-5 h-5 text-[#0a84ff]" />
                <h3 className="text-[17px] font-medium text-white">Objectifs mensuels</h3>
              </div>
            </div>

            <div className="p-4 space-y-4">
              <div>
                <label className="block text-[13px] text-[#98989f] mb-2">
                  Volume cible
                </label>
                <input
                  type="number"
                  value={formData.monthlyGoal.volumeTarget}
                  onChange={(e) => setFormData({
                    ...formData,
                    monthlyGoal: {
                      ...formData.monthlyGoal,
                      volumeTarget: Number(e.target.value)
                    }
                  })}
                  className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                />
                <p className="text-[13px] text-[#98989f] mt-1">
                  Actuellement : {formatCurrency(formData.monthlyGoal.volumeTarget)}
                </p>
              </div>

              <div>
                <label className="block text-[13px] text-[#98989f] mb-2">
                  Récompense
                </label>
                <input
                  type="text"
                  value={formData.monthlyGoal.reward.description}
                  onChange={(e) => setFormData({
                    ...formData,
                    monthlyGoal: {
                      ...formData.monthlyGoal,
                      reward: {
                        ...formData.monthlyGoal.reward,
                        description: e.target.value
                      }
                    }
                  })}
                  className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                />
              </div>
            </div>
          </div>

          {/* EmailJS Configuration */}
          <div className="bg-[#2c2c2e] rounded-xl overflow-hidden">
            <div className="p-4 border-b border-[#3a3a3c]">
              <div className="flex items-center gap-3">
                <Mail className="w-5 h-5 text-[#0a84ff]" />
                <h3 className="text-[17px] font-medium text-white">Configuration EmailJS</h3>
              </div>
            </div>

            <div className="p-4 space-y-4">
              <div>
                <label className="block text-[13px] text-[#98989f] mb-2">
                  Service ID
                </label>
                <input
                  type="text"
                  value={formData.emailjs.serviceId}
                  onChange={(e) => setFormData({
                    ...formData,
                    emailjs: {
                      ...formData.emailjs,
                      serviceId: e.target.value
                    }
                  })}
                  className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                />
              </div>

              <div>
                <label className="block text-[13px] text-[#98989f] mb-2">
                  Template ID
                </label>
                <input
                  type="text"
                  value={formData.emailjs.templateId}
                  onChange={(e) => setFormData({
                    ...formData,
                    emailjs: {
                      ...formData.emailjs,
                      templateId: e.target.value
                    }
                  })}
                  className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                />
              </div>

              <div>
                <label className="block text-[13px] text-[#98989f] mb-2">
                  Public Key
                </label>
                <input
                  type="text"
                  value={formData.emailjs.publicKey}
                  onChange={(e) => setFormData({
                    ...formData,
                    emailjs: {
                      ...formData.emailjs,
                      publicKey: e.target.value
                    }
                  })}
                  className="w-full px-4 py-3 bg-[#3a3a3c] text-white border-none rounded-xl focus:ring-2 focus:ring-[#0a84ff] placeholder-[#98989f]"
                />
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={isSaving}
            className="w-full px-4 py-3 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors disabled:opacity-50 flex items-center justify-center gap-2"
          >
            <Save className="w-5 h-5" />
            {isSaving ? 'Enregistrement...' : 'Enregistrer les modifications'}
          </button>
        </form>

        {/* Bottom Safe Area */}
        <div className="h-8" />
      </div>

      {/* Manual Bonus Form */}
      {showBonusForm && (
        <ManualBonusForm onClose={() => setShowBonusForm(false)} />
      )}
    </div>
  );
}