import React from 'react';
import { motion } from 'framer-motion';
import { X, Calendar, DollarSign, Gift } from 'lucide-react';
import { formatCurrency, formatEuro } from '../../utils/format';
import { formatDateDisplay } from '../../utils/date';
import BlurredNumber from '../ui/BlurredNumber';
import { calculateCommission } from '../../utils/commission';
import type { Sale } from '../../types';

interface MonthlyRevenueModalProps {
  month: string;
  sales: Sale[];
  onClose: () => void;
}

export default function MonthlyRevenueModal({ month, sales, onClose }: MonthlyRevenueModalProps) {
  // Calculate total commission for the month
  const totalCommission = sales.reduce((total, sale) => {
    const commission = calculateCommission(sale);
    return total + commission.agency + (sale.bonus ? commission.bonus.agency : 0);
  }, 0);

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-xl flex items-center justify-center z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        className="w-full max-w-lg bg-[#1c1c1e] rounded-2xl overflow-hidden m-4"
      >
        {/* Header */}
        <div className="p-4 border-b border-[#2c2c2e]">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
                <Calendar className="w-6 h-6 text-[#0a84ff]" />
              </div>
              <div>
                <h2 className="text-[22px] font-semibold text-white">{month}</h2>
                <p className="text-[13px] text-[#98989f]">{sales.length} ventes</p>
              </div>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-[#2c2c2e] rounded-xl transition-colors"
            >
              <X className="w-5 h-5 text-[#98989f]" />
            </button>
          </div>
        </div>

        {/* Total Commission */}
        <div className="p-4 border-b border-[#2c2c2e]">
          <div className="flex items-center gap-3 mb-2">
            <DollarSign className="w-5 h-5 text-[#30d158]" />
            <p className="text-[15px] text-[#98989f]">Commission totale</p>
          </div>
          <p className="text-[28px] font-bold text-[#30d158] mb-1">
            <BlurredNumber value={formatCurrency(totalCommission)} />
          </p>
          <p className="text-[13px] text-[#98989f]">
            <BlurredNumber value={formatEuro(totalCommission)} />
          </p>
        </div>

        {/* Sales List */}
        <div className="max-h-[60vh] overflow-y-auto">
          {sales.map((sale) => {
            const commission = calculateCommission(sale);
            const agencyCommission = commission.agency + (sale.bonus ? commission.bonus.agency : 0);

            return (
              <div key={sale.id} className="px-4 py-3 border-b border-[#2c2c2e] hover:bg-[#2c2c2e] transition-colors">
                <div className="flex items-start justify-between">
                  <div>
                    <h3 className="text-[17px] font-medium text-white">{sale.project}</h3>
                    <p className="text-[15px] text-[#98989f]">Unité {sale.unitNumber}</p>
                    <div className="flex items-center gap-2 mt-2 text-[13px] text-[#98989f]">
                      <Calendar className="w-4 h-4" />
                      <span>{formatDateDisplay(sale.saleDate)}</span>
                    </div>
                  </div>
                  <div className="text-right">
                    <p className="text-[17px] font-bold text-white">
                      <BlurredNumber value={formatCurrency(agencyCommission)} />
                    </p>
                    <p className="text-[13px] text-[#98989f]">
                      <BlurredNumber value={formatEuro(agencyCommission)} />
                    </p>
                    {sale.bonus && (
                      <div className="flex items-center justify-end gap-1 mt-1 text-[13px] text-[#bf5af2]">
                        <Gift className="w-4 h-4" />
                        +<BlurredNumber value={formatCurrency(commission.bonus.agency)} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </motion.div>
    </div>
  );
}