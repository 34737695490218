export function formatCurrency(amount: number): string {
  const formattedNumber = new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount);
  
  return `${formattedNumber} AED`;
}

export function formatEuro(aedAmount: number): string {
  const eurAmount = aedAmount * 0.25;
  
  const formattedNumber = new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(eurAmount);
  
  return `${formattedNumber} €`;
}

export function formatDate(date: string | Date): string {
  const d = new Date(date);
  return d.toLocaleDateString('fr-FR');
}

export function formatPhoneNumber(phone: string): string {
  return phone.replace(/[^0-9+]/g, '');
}