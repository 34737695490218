import React from 'react';
import { useParams } from 'react-router-dom';
import { useSales } from '../../hooks/useSales';
import { useAgents } from '../../hooks/useAgents';
import SaleHeader from '../../components/sale/SaleHeader';
import ClientInfoCard from '../../components/sale/ClientInfoCard';
import SalePriceCard from '../../components/sale/SalePriceCard';
import CommissionBreakdown from '../../components/sale/CommissionBreakdown';
import PaymentStatusCard from '../../components/sale/PaymentStatusCard';
import AdvanceCommissionCard from '../../components/sale/AdvanceCommissionCard';

export default function AgentSaleDetails() {
  const { id } = useParams<{ id: string }>();
  const { sales, calculateCommission } = useSales();
  const { agents } = useAgents();
  
  const sale = sales.find(s => s.id === id);
  if (!sale) return null;

  const commission = calculateCommission(sale);

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <SaleHeader 
        project={sale.project} 
        unitNumber={sale.unitNumber} 
      />

      {/* Main Content */}
      <div className="max-w-lg mx-auto px-4 space-y-4 pt-4">
        {/* Client Info */}
        <ClientInfoCard 
          client={sale.client} 
          leadSource={sale.leadSource} 
        />

        {/* Price and Date */}
        <SalePriceCard 
          price={sale.propertyPrice} 
          saleDate={sale.saleDate}
          developerCommissionPercentage={sale.developerCommissionPercentage}
        />

        {/* Commission Breakdown */}
        <CommissionBreakdown 
          sale={sale} 
          commission={commission}
          agents={agents}
        />

        {/* Payment Status */}
        <PaymentStatusCard 
          sale={sale} 
          commission={{
            ...commission,
            myCommission: commission.agent,
            myRole: 'Agent principal',
            myPaymentStatus: sale.paymentStatus.agent
          }}
        />

        {/* Commission Advance Request - Show if not paid */}
        {!sale.paymentStatus.agent && (
          <AdvanceCommissionCard 
            sale={sale} 
            commission={{
              agent: commission.agent,
              role: 'Agent principal'
            }}
          />
        )}

        {/* Bottom Spacing */}
        <div className="h-8" />
      </div>
    </div>
  );
}