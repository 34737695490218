// src/pages/AgentProfile.tsx
import React, { useState, useMemo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Building, Calendar, Mail, Phone, ChevronLeft, FileText, DollarSign, User, TrendingUp, Download, Key, Edit } from 'lucide-react';
import { differenceInDays } from 'date-fns';
import { useSales } from '../hooks/useSales';
import { useAgents } from '../hooks/useAgents';
import { formatDateDisplay } from '../utils/date';
import { formatCurrency, formatEuro } from '../utils/format';
import Avatar from '../components/ui/Avatar';
import BlurredNumber from '../components/ui/BlurredNumber';
import { motion } from 'framer-motion';
import ResetPasswordForm from '../components/auth/ResetPasswordForm';
import AgentForm from '../components/AgentForm';

export default function AgentProfile() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { sales, calculateCommission } = useSales();
  const { agents } = useAgents();
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  const agentSales = useMemo(() => {
    if (!id) return [];
    return sales
      .filter(sale => sale.agentId === id || sale.referrerId === id)
      .sort((a, b) => new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime());
  }, [sales, id]);

  const stats = useMemo(() => {
    if (!id || !agentSales.length) {
      return {
        rank: 0,
        totalAgents: agents.length,
        agencyCommission: 0,
        bonus: 0,
        daysSinceLastSale: null,
        monthlyAverage: 0,
        totalVolume: 0,
        salesCount: 0
      };
    }

    // Calculate rank
    const rankings = agents
      .map(a => ({
        id: a.id,
        commission: sales
          .filter(s => s.agentId === a.id || s.referrerId === a.id)
          .reduce((sum, sale) => {
            const comm = calculateCommission(sale);
            return sum + comm.agency + (sale.bonus ? comm.bonus.agency : 0);
          }, 0)
      }))
      .sort((a, b) => b.commission - a.commission);

    const rank = rankings.findIndex(r => r.id === id) + 1;

    // Calculate other stats
    const firstSale = agentSales[agentSales.length - 1];
    const lastSale = agentSales[0];
    const monthsDiff = differenceInDays(new Date(), new Date(firstSale.saleDate)) / 30;
    
    const totalStats = agentSales.reduce((acc, sale) => {
      const commission = calculateCommission(sale);
      acc.totalVolume += sale.propertyPrice;
      acc.totalUnits += 1;
      acc.totalProjects.add(sale.project);
      acc.totalAgencyCommission += commission.agency;
      return acc;
    }, {
      totalVolume: 0,
      totalUnits: 0,
      totalProjects: new Set<string>(),
      totalAgencyCommission: 0
    });

    return {
      rank,
      totalAgents: agents.length,
      agencyCommission: totalStats.totalAgencyCommission,
      bonus: totalStats.bonus,
      daysSinceLastSale: lastSale ? differenceInDays(new Date(), new Date(lastSale.saleDate)) : null,
      monthlyAverage: agentSales.length / Math.max(1, monthsDiff),
      totalVolume: totalStats.totalVolume,
      salesCount: agentSales.length
    };
  }, [id, agents, sales, agentSales, calculateCommission]);

  const agent = useMemo(() => agents.find(a => a.id === id), [agents, id]);
  if (!agent) return null;

  return (
    <div className="min-h-screen bg-black">
      {/* Header */}
      <div className="bg-[#1c1c1e] sticky top-0 z-50 w-full border-b border-[#2c2c2e]">
        <div className="max-w-lg mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <button
                onClick={() => navigate(-1)}
                className="w-8 h-8 flex items-center justify-center rounded-xl hover:bg-[#2c2c2e] transition-colors"
              >
                <ChevronLeft className="w-5 h-5 text-[#0a84ff]" />
              </button>
              <div>
                <h1 className="text-[17px] font-semibold text-white">{agent.name}</h1>
                <p className="text-[13px] text-[#98989f]">Agent</p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={() => setShowEditForm(true)}
                className="px-4 py-2 bg-[#0a84ff] text-white rounded-xl hover:bg-[#0a84ff]/90 transition-colors flex items-center gap-2"
              >
                <Edit className="w-4 h-4" />
                Modifier l'agent
              </button>
              <button
                onClick={() => setShowResetPassword(true)}
                className="px-4 py-2 bg-[#2c2c2e] text-[#98989f] rounded-xl hover:bg-[#3a3a3c] transition-colors flex items-center gap-2"
              >
                <Key className="w-4 h-4" />
                Réinitialiser le mot de passe
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-lg mx-auto px-4 py-4 space-y-4">
        {/* Business Card */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-[#2c2c2e] rounded-xl overflow-hidden"
        >
          <div className="p-6">
            <div className="flex items-center gap-4 mb-6">
              <Avatar name={agent.name} size="lg" />
              <div>
                <h2 className="text-[22px] font-semibold text-white">{agent.name}</h2>
                <p className="text-[15px] text-[#98989f]">Agent Immobilier</p>
              </div>
            </div>

            <div className="space-y-4">
              <a 
                href={`mailto:${agent.email}`}
                className="flex items-center gap-3 p-4 bg-[#3a3a3c] rounded-xl hover:bg-[#48484a] transition-colors"
              >
                <Mail className="w-5 h-5 text-[#0a84ff]" />
                <span className="text-[15px] text-white">{agent.email}</span>
              </a>

              {agent.phone && (
                <a 
                  href={`tel:${agent.phone}`}
                  className="flex items-center gap-3 p-4 bg-[#3a3a3c] rounded-xl hover:bg-[#48484a] transition-colors"
                >
                  <Phone className="w-5 h-5 text-[#30d158]" />
                  <span className="text-[15px] text-white">{agent.phone}</span>
                </a>
              )}
            </div>
          </div>
        </motion.div>

        {/* Performance Stats */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="bg-[#2c2c2e] rounded-xl overflow-hidden"
        >
          <div className="p-4 border-b border-[#3a3a3c]">
            <div className="flex items-center gap-3">
              <Building className="w-5 h-5 text-[#0a84ff]" />
              <h3 className="text-[17px] font-medium text-white">Performance</h3>
            </div>
          </div>

          <div className="p-4 space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <p className="text-[13px] text-[#98989f] mb-2">Volume total</p>
                <p className="text-[17px] font-bold text-white">
                  <BlurredNumber value={formatCurrency(stats.totalVolume)} />
                </p>
                <p className="text-[13px] text-[#98989f]">
                  <BlurredNumber value={formatEuro(stats.totalVolume)} />
                </p>
              </div>

              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <p className="text-[13px] text-[#98989f] mb-2">Commission agence</p>
                <p className="text-[17px] font-bold text-white">
                  <BlurredNumber value={formatCurrency(stats.agencyCommission)} />
                </p>
                <p className="text-[13px] text-[#98989f]">
                  <BlurredNumber value={formatEuro(stats.agencyCommission)} />
                </p>
              </div>

              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <p className="text-[13px] text-[#98989f] mb-2">Nombre de ventes</p>
                <p className="text-[17px] font-bold text-white">{stats.salesCount}</p>
                <p className="text-[13px] text-[#98989f]">
                  {stats.monthlyAverage.toFixed(1)} ventes/mois
                </p>
              </div>

              <div className="p-4 bg-[#3a3a3c] rounded-xl">
                <p className="text-[13px] text-[#98989f] mb-2">Dernière vente</p>
                <p className="text-[17px] font-bold text-white">
                  {stats.daysSinceLastSale !== null
                    ? `${stats.daysSinceLastSale} jours`
                    : 'Aucune vente'}
                </p>
                <p className="text-[13px] text-[#98989f]">
                  #{stats.rank} sur {stats.totalAgents} agents
                </p>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Sales History */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-[#2c2c2e] rounded-xl overflow-hidden"
        >
          <div className="p-4 border-b border-[#3a3a3c]">
            <div className="flex items-center gap-3">
              <DollarSign className="w-5 h-5 text-[#0a84ff]" />
              <h3 className="text-[17px] font-medium text-white">Historique des ventes</h3>
            </div>
          </div>

          <div className="divide-y divide-[#3a3a3c]">
            {agentSales.map(sale => {
              const commission = calculateCommission(sale);
              const myCommission = sale.agentId === agent.id ? commission.agent : commission.referrer;

              return (
                <Link
                  key={sale.id}
                  to={`/sales/${sale.id}`}
                  className="block p-4 hover:bg-[#3a3a3c] transition-colors"
                >
                  <div className="flex items-start justify-between">
                    <div>
                      <h4 className="text-[15px] font-medium text-white">{sale.project}</h4>
                      <p className="text-[13px] text-[#98989f]">Unité {sale.unitNumber}</p>
                      <div className="flex items-center gap-2 mt-2">
                        <Calendar className="w-4 h-4 text-[#0a84ff]" />
                        <span className="text-[13px] text-[#98989f]">
                          {formatDateDisplay(sale.saleDate)}
                        </span>
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="text-[15px] font-bold text-white">
                        <BlurredNumber value={formatCurrency(myCommission || 0)} />
                      </p>
                      <div className={`mt-2 px-3 py-1 rounded-xl text-[13px] font-medium ${
                        sale.paymentStatus.agent
                          ? 'bg-[#26382f] text-[#30d158]'
                          : 'bg-[#3a2c29] text-[#ff453a]'
                      }`}>
                        {sale.paymentStatus.agent ? 'Payée' : 'En attente'}
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}

            {agentSales.length === 0 && (
              <div className="text-center py-12">
                <Building className="w-12 h-12 text-[#98989f] mx-auto mb-4" />
                <p className="text-[#98989f]">Aucune vente trouvée</p>
              </div>
            )}
          </div>
        </motion.div>

        {/* Bottom Safe Area */}
        <div className="h-8" />
      </div>

      {/* Reset Password Modal */}
      {showResetPassword && (
        <ResetPasswordForm
          agent={agent}
          onClose={() => setShowResetPassword(false)}
        />
      )}
      {showEditForm && (
        <AgentForm
          initialData={agent}
          onSubmit={async (agentData) => {
            await updateAgent(agent.id, agentData);
            setShowEditForm(false);
          }}
          onClose={() => setShowEditForm(false)}
          agents={agents}
        />
      )}
    </div>
  );
}