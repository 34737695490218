import React from 'react';
import { motion } from 'framer-motion';
import { Info, Gift, Users, DollarSign, TrendingUp, ChevronRight, Building, Video, MessageSquare, AlertTriangle, Clock, Calendar, Instagram, AlertCircle, GraduationCap, Home, Globe, Target } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';
import { useAgentSlug } from '../../hooks/useAgentSlug';
import AgentRules from '../../components/agent-dashboard/AgentRules';
import ReferralBanner from '../../components/agent-dashboard/ReferralBanner';
import VideographerService from '../../components/agent-dashboard/VideographerService';

export default function AgentInformation() {
  const { agentSlug } = useParams();
  const { agent } = useAgentSlug();

  if (!agent) return null;

  return (
    <div className="min-h-screen bg-[#000000]">
      {/* Header */}
      <div className="bg-[#1c1c1e] sticky top-0 z-50 w-full border-b border-[#2c2c2e]">
        <div className="max-w-lg mx-auto px-4 py-3">
          <div className="flex items-center gap-3">
            <Info className="w-6 h-6 text-[#0a84ff]" />
            <h1 className="text-[17px] font-semibold text-white">Information</h1>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-lg mx-auto px-4 py-4 space-y-6">
        {/* Rules Section - iOS 17 Style */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-[#2c2c2e] rounded-2xl overflow-hidden"
        >
          <div className="p-4 border-b border-[#3a3a3c]">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#ff453a]/10 flex items-center justify-center">
                <AlertTriangle className="w-6 h-6 text-[#ff453a]" />
              </div>
              <div>
                <h3 className="text-[17px] font-semibold text-white">Règles Importantes</h3>
                <p className="text-[13px] text-[#98989f]">À respecter impérativement</p>
              </div>
            </div>
          </div>

          <div className="divide-y divide-[#3a3a3c]">
            {/* Lead Structure */}
            <div className="p-4">
              <div className="flex items-center gap-3 mb-2">
                <Target className="w-5 h-5 text-[#30d158]" />
                <h4 className="text-[15px] font-medium text-white">Structure des leads</h4>
              </div>
              <div className="space-y-2 pl-8">
                <p className="text-[15px] text-[#98989f]">
                  • 1-25 ventes : Lead Normal (moins prioritaire et lead budget restreint)
                </p>
                <p className="text-[15px] text-[#98989f]">
                  • 25-50 ventes : Lead Premium
                </p>
                <p className="text-[15px] text-[#98989f]">
                  • +50 ventes : Lead ELITE (Palm Jumeirah, Penthouse, Luxury)
                </p>
              </div>
            </div>

            <div className="p-4">
              <div className="flex items-center gap-3 mb-2">
                <Clock className="w-5 h-5 text-[#0a84ff]" />
                <h4 className="text-[15px] font-medium text-white">Heures de travail</h4>
              </div>
              <p className="text-[15px] text-[#98989f] pl-8">
                Disponibilité requise de 12h à 22h
              </p>
            </div>

            <div className="p-4">
              <div className="flex items-center gap-3 mb-2">
                <AlertCircle className="w-5 h-5 text-[#ff453a]" />
                <h4 className="text-[15px] font-medium text-white">Gestion des leads</h4>
              </div>
              <div className="space-y-2 pl-8">
                <p className="text-[15px] text-[#98989f]">
                  • Contacter le lead dans l'heure (25€ de pénalité)
                </p>
                <p className="text-[15px] text-[#98989f]">
                  • Les leads sont strictement individuels
                </p>
                <p className="text-[15px] text-[#98989f]">
                  • Vol de lead = suspension d'un trimestre
                </p>
              </div>
            </div>

            <div className="p-4">
              <div className="flex items-center gap-3 mb-2">
                <Calendar className="w-5 h-5 text-[#30d158]" />
                <h4 className="text-[15px] font-medium text-white">Disponibilité</h4>
              </div>
              <p className="text-[15px] text-[#98989f] pl-8">
                Prévenez 24h à l'avance si vous êtes indisponible
              </p>
            </div>

            <div className="p-4">
              <div className="flex items-center gap-3 mb-2">
                <Instagram className="w-5 h-5 text-[#ff9f0a]" />
                <h4 className="text-[15px] font-medium text-white">Instagram professionnel</h4>
              </div>
              <div className="space-y-2 pl-8">
                <p className="text-[15px] text-[#98989f]">
                  • Compte professionnel obligatoire
                </p>
                <p className="text-[15px] text-[#98989f]">
                  • Mention @palmdubai.fr en BIO
                </p>
                <p className="text-[15px] text-[#98989f]">
                  • Publication régulière de contenu
                </p>
                <p className="text-[15px] text-[#98989f]">
                  • Challenge mensuel avec récompenses
                </p>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Referral Banner */}
        <ReferralBanner />

        {/* Office Space Banner */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gradient-to-br from-blue-900 to-indigo-900 rounded-xl border border-blue-700/50 p-6 text-white relative overflow-hidden"
        >
          {/* Animated background elements */}
          <motion.div
            className="absolute top-0 right-0 w-96 h-96 bg-blue-500/10 rounded-full blur-3xl"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.3, 0.5, 0.3],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />

          <div className="relative">
            <div className="flex items-center gap-4 mb-6">
              <motion.div
                className="flex-shrink-0"
                animate={{
                  rotate: [0, 10, 0, -10, 0],
                  scale: [1, 1.1, 1],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
                className="p-3 bg-gradient-to-br from-blue-400 to-blue-500 rounded-xl shadow-lg"
              >
                <Home className="w-8 h-8 text-white" />
              </motion.div>
              <div className="min-w-0">
                <h3 className="text-xl font-semibold">Espace de travail</h3>
                <p className="text-blue-200 truncate">
                  Accès 24h/24 7j/7
                </p>
              </div>
            </div>

            <p className="text-lg text-white/90 mb-8">
              Un espace de travail dédié est disponible pour que vous puissiez travailler dans les meilleures conditions.
            </p>

            <Link
              to={`/agentcrm/${agentSlug}/office`}
              className="inline-flex items-center gap-3 px-6 py-3 bg-white text-blue-600 rounded-xl hover:bg-blue-50 transition-all duration-200 group"
            >
              <Building className="w-5 h-5" />
              <span className="font-medium">Réserver l'espace</span>
              <ChevronRight className="w-5 h-5 transition-transform group-hover:translate-x-1" />
            </Link>
          </div>
        </motion.div>

        {/* Referral Program - iOS App Store Style */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-[#2c2c2e] rounded-2xl overflow-hidden"
        >
          <div className="p-4 border-b border-[#3a3a3c]">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#bf5af2]/10 flex items-center justify-center">
                <Gift className="w-6 h-6 text-[#bf5af2]" />
              </div>
              <div>
                <h3 className="text-[17px] font-semibold text-white">Programme de Parrainage</h3>
                <p className="text-[13px] text-[#98989f]">Gagnez 10% à vie !</p>
              </div>
            </div>
          </div>

          <div className="p-4 space-y-4">
            <div className="bg-[#3a3a3c] rounded-xl p-4">
              <div className="flex items-center gap-3 mb-2">
                <DollarSign className="w-5 h-5 text-[#30d158]" />
                <h4 className="text-[15px] font-medium text-white">Premier bonus</h4>
              </div>
              <p className="text-[15px] text-[#98989f]">
                20% sur la première vente de chaque filleul
              </p>
            </div>

            <div className="bg-[#3a3a3c] rounded-xl p-4">
              <div className="flex items-center gap-3 mb-2">
                <TrendingUp className="w-5 h-5 text-[#30d158]" />
                <h4 className="text-[15px] font-medium text-white">Revenus passifs</h4>
              </div>
              <p className="text-[15px] text-[#98989f]">
                10% sur toutes les ventes futures, à vie !
              </p>
            </div>

            <div className="bg-[#3a3a3c] rounded-xl p-4">
              <div className="flex items-center gap-3 mb-2">
                <Users className="w-5 h-5 text-[#30d158]" />
                <h4 className="text-[15px] font-medium text-white">Équipe illimitée</h4>
              </div>
              <p className="text-[15px] text-[#98989f]">
                Aucune limite sur le nombre d'agents parrainés
              </p>
            </div>
          </div>

          <div className="p-4 border-t border-[#3a3a3c]">
            <button
              onClick={() => {
                const message = encodeURIComponent("Bonjour, je souhaite parrainer un agent chez Palm Dubai Immo.");
                window.open(`https://wa.me/971581998661?text=${message}`, '_blank');
              }}
              className="w-full flex items-center justify-between"
            >
              <div className="flex items-center gap-3">
                <MessageSquare className="w-5 h-5 text-[#0a84ff]" />
                <span className="text-[15px] text-white">Contacter pour parrainer</span>
              </div>
              <ChevronRight className="w-5 h-5 text-[#98989f]" />
            </button>
          </div>
        </motion.div>
        
        {/* Videographer Service - iOS Settings Style */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-[#2c2c2e] rounded-2xl overflow-hidden"
        >
          <div className="p-4 border-b border-[#3a3a3c]">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 rounded-xl bg-[#ff453a]/10 flex items-center justify-center">
                <Video className="w-6 h-6 text-[#ff453a]" />
              </div>
              <div>
                <h3 className="text-[17px] font-semibold text-white">Service Vidéaste</h3>
                <p className="text-[13px] text-[#98989f]">Service suspendu</p>
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="flex items-center gap-3 bg-[#3a2c29] rounded-xl p-4">
              <AlertTriangle className="w-5 h-5 text-[#ff453a]" />
              <div>
                <p className="text-[15px] text-[#ff453a] font-medium">Service temporairement indisponible</p>
                <p className="text-[13px] text-[#98989f] mt-1">
                  Nous vous informerons dès la reprise du service vidéaste.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
        
        {/* Bottom Safe Area */}
        <div className="h-8" />
      </div>
    </div>
  );
}