import React, { useMemo } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { TrendingUp } from 'lucide-react';
import { useSales } from '../../hooks/useSales';
import { formatCurrency } from '../../utils/format';
import BlurredNumber from '../../components/ui/BlurredNumber';

export default function PerformanceChart() {
  const { sales, calculateCommission } = useSales();

  // Prepare monthly data
  const monthlyData = useMemo(() => {
    // Create data structure for all months including future months
    const months = [
      'août 2024',
      'septembre 2024',
      'octobre 2024',
      'novembre 2024',
      'décembre 2024',
      'janvier 2025',
      'février 2025',
      'mars 2025'
    ];

    // Initialize data structure with 0 values for all months
    const initialData = months.reduce((acc, month) => {
      acc[month] = {
        month,
        volume: 0,
        sales: 0,
        commission: 0
      };
      return acc;
    }, {} as Record<string, {
      month: string;
      volume: number;
      sales: number;
      commission: number;
    }>);

    // Fill in actual data from sales
    sales.forEach(sale => {
      const date = new Date(sale.saleDate);
      const monthKey = date.toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
      
      if (initialData[monthKey]) {
        const commission = calculateCommission(sale);
        initialData[monthKey].volume += sale.propertyPrice;
        initialData[monthKey].sales += 1;
        initialData[monthKey].commission += commission.agency;
      }
    });

    return Object.values(initialData);
  }, [sales]);

  return (
    <div className="bg-[#1c1c1e] rounded-2xl overflow-hidden">
      <div className="p-6 border-b border-[#2c2c2e]">
        <div className="flex items-center gap-3">
          <div className="w-10 h-10 rounded-xl bg-[#0a84ff]/10 flex items-center justify-center">
            <TrendingUp className="w-6 h-6 text-[#0a84ff]" />
          </div>
          <div>
            <h3 className="text-[17px] font-semibold text-white">Performance</h3>
            <p className="text-[13px] text-[#98989f]">Évolution mensuelle</p>
          </div>
        </div>
      </div>

      <div className="p-6">
        <div className="h-[400px]">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={monthlyData}>
              <defs>
                <linearGradient id="colorVolume" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#0a84ff" stopOpacity={0.3}/>
                  <stop offset="95%" stopColor="#0a84ff" stopOpacity={0}/>
                </linearGradient>
                <linearGradient id="colorSales" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#30d158" stopOpacity={0.3}/>
                  <stop offset="95%" stopColor="#30d158" stopOpacity={0}/>
                </linearGradient>
                <linearGradient id="colorCommission" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#bf5af2" stopOpacity={0.3}/>
                  <stop offset="95%" stopColor="#bf5af2" stopOpacity={0}/>
                </linearGradient>
              </defs>

              <CartesianGrid strokeDasharray="3 3" stroke="#2c2c2e" />
              
              <XAxis 
                dataKey="month" 
                tick={{ fill: '#98989f', fontSize: 12 }}
                tickLine={false}
                axisLine={false}
              />
              
              <YAxis
                yAxisId="left"
                tick={{ fill: '#98989f', fontSize: 12 }}
                tickLine={false}
                axisLine={false}
                tickFormatter={formatCurrency}
              />
              
              <YAxis
                yAxisId="right"
                orientation="right"
                tick={{ fill: '#98989f', fontSize: 12 }}
                tickLine={false}
                axisLine={false}
                tickFormatter={(value) => `${value} ventes`}
              />

              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="bg-[#2c2c2e] rounded-xl shadow-lg border border-[#3a3a3c] p-4">
                        <div className="text-center border-b border-[#3a3a3c] pb-2 mb-3">
                          <p className="text-[15px] font-medium text-white">{payload[0].payload.month}</p>
                        </div>
                        
                        <div className="space-y-3">
                          <div>
                            <p className="text-[13px] text-[#98989f] mb-1">Volume total</p>
                            <p className="text-[15px] font-medium text-[#0a84ff]">
                              <BlurredNumber value={formatCurrency(payload[0].payload.volume)} />
                            </p>
                          </div>

                          <div>
                            <p className="text-[13px] text-[#98989f] mb-1">Commission agence</p>
                            <p className="text-[15px] font-medium text-[#bf5af2]">
                              <BlurredNumber value={formatCurrency(payload[0].payload.commission)} />
                            </p>
                          </div>

                          <div className="pt-2 border-t border-[#3a3a3c]">
                            <p className="text-[13px] text-[#98989f] mb-1">Ventes</p>
                            <p className="text-[15px] font-medium text-[#30d158]">
                              {payload[0].payload.sales} ventes
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return null;
                }}
              />

              <Area
                yAxisId="left"
                type="monotone"
                dataKey="volume"
                stroke="#0a84ff"
                strokeWidth={2}
                fill="url(#colorVolume)"
              />

              <Area
                yAxisId="left"
                type="monotone"
                dataKey="commission"
                stroke="#bf5af2"
                strokeWidth={2}
                fill="url(#colorCommission)"
              />

              <Area
                yAxisId="right"
                type="monotone"
                dataKey="sales"
                stroke="#30d158"
                strokeWidth={2}
                fill="url(#colorSales)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}